import { useState, useEffect } from 'react';
import MyFoodItemsIcon from '../../assets/myFoodItemsIcon.svg';
import api from '../../libs/api';
import { useNavigate } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import { captureException } from '@sentry/react';

export default function MyFoodItemsWidget() {
    const navigate = useNavigate();
    const [foodItemsCount, setFooditemsCount] = useState(0);

    useEffect(() => {
        async function getFoodItemCount() {
            try {
                const apiResponse = await api.get(`/myfooditems/count/${firebase.auth().currentUser?.uid}`);
                if (apiResponse.status === 200) {
                    const foodItemsCount = apiResponse.data as number;
                    setFooditemsCount(foodItemsCount);
                }
            } catch (error) {
                captureException(error);
            }
        }
        void getFoodItemCount();
    }, [])

    return (
        <div className="lg:min-w-[380px] lg:max-w-5xl border rounded shadow">
            <div className="flex flex-row justify-between p-4 bg-[#FBFFFA] h-[121px]">
                <div>
                    <p className='text-xs'>My Food Items</p>
                    <p className='text-3xl font-bold mt-2'>{foodItemsCount}</p>
                </div>
                <img src={MyFoodItemsIcon} />
            </div>
            <div className='flex flex-row-reverse justify-between border-t rounded bg-[#F9F9F9] py-4 px-4 h-[58px]'>
                <p onClick={() => navigate('/my-food-items')} className='text-sm font-semibold cursor-pointer'>View more</p>
            </div>
        </div>
    )
}