import React from 'react';

const ErrorPage: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-600 to-purple-700 text-white p-5">
      <div className="text-center max-w-lg mx-auto">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mt-5">Oops!</h1>
        <p className="text-lg sm:text-xl lg:text-2xl mt-3 px-2">
          Something went wrong. We're working to fix it.
        </p>
        <div className="mt-6 flex flex-col sm:flex-row justify-center items-center space-y-3 sm:space-y-0 sm:space-x-4 px-2">
          <button
            className="w-full sm:w-auto bg-white text-purple-700 px-5 py-2 rounded-full font-semibold hover:bg-purple-700 hover:text-white transition duration-300"
            onClick={() => window.location.reload()}
          >
            Refresh Page
          </button>
          <button
            className="w-full sm:w-auto bg-white text-purple-700 px-5 py-2 rounded-full font-semibold hover:bg-purple-700 hover:text-white transition duration-300"
            onClick={() => (window.location.href = '/')}
          >
            Go to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
