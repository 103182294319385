export function formatNumber(
    number: number,
    decimalPlaces: number = 2,
    useCommas: boolean = true
  ): string {
    if(number === 0) return '0';
    if(!number) return 'Unavailable';
    let formattedNumber = number.toFixed(decimalPlaces);
  
    if (useCommas) {
      const parts = formattedNumber.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      formattedNumber = parts.join('.');
    }
  
    return formattedNumber;
  }