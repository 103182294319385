import { useState, useEffect } from "react";
import api from "../libs/api";
import { Market } from "../interfaces/market.interface";
import background from "../assets/background-image.png";
import { useNavigate } from "react-router-dom";
import "firebase/auth";
import { analytics } from "../firebase-config";
import { FaMapMarkerAlt } from "react-icons/fa";
import Header from "../components/feature/Header";
import MobileHeader from "../components/feature/MobileHeader";
import { Helmet } from "react-helmet-async";

export default function Markets() {
    analytics.logEvent('page_view', {
        page_title: 'market'
    });
    const navigate = useNavigate();
    const [markets, setMarkets] = useState<Market[]>();

    useEffect(() => {
        async function getAllMarkets() {
            const apiData = await api.get('/commodity/getAllMarket');
            if (apiData.status === 200) {
                setMarkets(apiData.data as Market[]);
            }
        }
        void getAllMarkets();
    }, [])

    const gotoHompage = async () => {
        navigate('/signup');
    }

    const openMaps = (query: string) => {
        const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(query)}`;
        window.open(url, "_blank");
    };

    const MarketItem = (market: Market) => {
        return (
            <div onClick={() => openMaps(`${market.name} ${market.state}`)} className="flex flex-col gap-2 p-3 border rounded-md cursor-pointer">
                <p className="text-sm">{market.name}</p>
                <p className=" bg-green-500 text-white text-sm font-bold rounded-md px-3 pt-1 pb-2 w-fit flex flex-row gap-1">
                    <FaMapMarkerAlt className="mt-1 text-xl" />
                    <span className="mt-1">{market.state}</span>
                </p>
            </div>
        )
    }
    return (
        <div>
            <Helmet>
                <title>Markets | Arket data</title>
                <meta name='description' content='We currently offer up to date price information in the following markets all across the country.' />
                <meta
                    name="keywords"
                    content="Arket markets, Arketdata, Arket data, Arket, markets,"
                />
            </Helmet>
            <div style={{ backgroundImage: `url(${background})`, height: 600 }}>
                <div className="lg:block hidden">
                    <Header />
                </div>
                <div className="lg:hidden block">
                    <MobileHeader />
                </div>
                <div className="lg:pt-12 pt-5 lg:pl-32 pl-5">
                    <div className="lg:w-2/3 h-80 flex-col justify-start items-start gap-6 inline-flex mt-36">
                        <div className="flex-col justify-start items-start gap-3 flex">
                            <div>
                                <p className="text-white lg:text-5xl text-3xl font-bold font-['Work Sans'] w-full">We currently offer up to date price information in the following markets all across the country.</p>
                                <p className="text-white text-xl font-['Work Sans'] mt-5">Tell us in the Chat which Market you want to see next.</p>
                            </div>
                        </div>
                        <button onClick={gotoHompage} className="px-3 py-2 bg-green-600 rounded text-white font-bold">
                            Create an account now!
                        </button>
                    </div>
                </div>
            </div>
            <div className="p-5">
                <div className="lg:grid lg:grid-cols-4 lg:gap-10 flex flex-col gap-5 mt-10">
                    {
                        markets?.map((marketItem) => (
                            <MarketItem {...marketItem} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}