/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Rating } from 'primereact/rating';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import { analytics } from '../firebase-config';

const Feedback = () => {
    analytics.logEvent('page_view', {
        page_title: 'feedback'
    });
    const toast = useRef<Toast>(null);
    const [rating, setRating] = useState<number | undefined>(0);
    const [feedbackCategory, setFeedbackCategory] = useState(null);
    const [feedbackText, setFeedbackText] = useState('');
    const [consent, setConsent] = useState(false);
    const [rated, setRated] = useState(false);

    const feedbackCategories = [
        { label: 'Bug Report', value: 'bug' },
        { label: 'Feature Request', value: 'feature' },
        { label: 'General Feedback', value: 'general' },
    ];
    const handleSubmit = async (e: any) => {
        try {
            e.preventDefault();

            if (!consent) {
                toast.current?.show({ severity: "info", summary: 'Please agree to the terms and conditions.' });
                return;
            }
            if (!rated) {
                toast.current?.show({ severity: "info", summary: 'Please select a rating.' });
                return;
            }
            const feedbackData = {
                rating,
                feedbackCategory,
                feedbackText,
                name: firebase.auth().currentUser?.displayName,
                date: new Date().toDateString(),
                status: false
            };
            await firebase.firestore().collection('feedback').add(feedbackData);
            await firebase.firestore().collection('users').doc(firebase.auth().currentUser?.uid).update({
                hasSubmittedFeedback: true
            })
    
            // Reset the form
            setRating(0);
            setFeedbackCategory(null);
            setFeedbackText('');
            setConsent(false);
    
            toast.current?.show({ severity: "success", summary: 'Thank you for your feedback!' });
        } catch (error) {
            console.log('eee', error);
            toast.current?.show({ severity: "error", summary: 'Something went wrong', detail: 'Please send us your feedback at info@arket.com' });
        }
    };

    return (
        <div className="min-h-screen lg:bg-[#F7F8FA] dark:bg-white flex items-center">
            <Card className="w-full max-w-lg mx-auto mt-2 p-6 pt-2 shadow-lg">
                <h2 className="text-3xl font-bold text-center mb-4 text-white">We Value Your Feedback!</h2>
                <p className="text-2xl text-center text-gray-800 mb-6">
                    Please share your thoughts to help us improve.
                </p>

                <form className='flex flex-col gap-5' onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label className="block text-lg mb-2">Rate Your Experience:</label>
                        <Rating
                            value={rating}
                            onChange={(e) => {
                                setRated(true);
                                setRating(e.value || 0)
                            }}
                            stars={5}
                            cancel={false}
                            className='text-6xl'
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-lg mb-2">Feedback Category:</label>
                        <Dropdown
                            value={feedbackCategory}
                            options={feedbackCategories}
                            onChange={(e) => setFeedbackCategory(e.value)}
                            placeholder="Select a Category"
                            className="w-full border"
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block text-lg mb-2">Your Feedback:</label>
                        <InputTextarea
                            value={feedbackText}
                            onChange={(e) => setFeedbackText(e.target.value)}
                            rows={5}
                            className="w-full border p-3"
                            placeholder="Share your thoughts..."
                        />
                    </div>
                    <div className="flex items-center mb-6">
                        <input
                            type="checkbox"
                            id="consent"
                            checked={consent}
                            onChange={(e) => setConsent(e.target.checked)}
                            className="mr-2 dark:text-white"
                        />
                        <label htmlFor="consent" className="text-gray-800">
                            I agree to the{' '}
                            <a href="#" className="text-blue-300 underline">
                                terms and conditions
                            </a>
                            .
                        </label>
                    </div>
                    <Button
                        label="Submit Feedback"
                        type="submit"
                        className="w-full p-button-rounded p-button-success dark:text-white"
                    />
                </form>
            </Card>
            <Toast ref={toast} />
        </div>
    );
};

export default Feedback;
