import { useState, useEffect } from "react";
import firebase from "firebase/app";
import MyFoodItemsWidget from "../components/feature/MyFoodItemsWidget";
import MyLastSearchWidget from "../components/feature/MyLastSearch";
import "firebase/auth";
import SearchStatistics from "../features/SearchStatistics";
import LastSearchMarketInsights from "../features/LastSearchMarketInsights";
import { analytics } from "../firebase-config";

export default function Home() {
    analytics.logEvent('page_view', {
        page_title: 'home'
    });
    const [lastSearch, setLastSearch] = useState<string>();

    useEffect(() => {
        // throw new Error('ttt')
    }, [])

    const updateLastSearch = (searchItem: string) => {
        setLastSearch(searchItem);
    }
    return (
        <div className={`h-[100vh] ${lastSearch ? 'lg:h-full' : ''} lg:px-10 bg-[#F7F8FA]`}>
            <div className="mt-10 lg:px-0 px-5">
                <p className="text-[1.5rem] text-[#060606] font-semibold">Greetings, {firebase.auth().currentUser?.displayName}</p>
            </div>
            <hr className="my-5" />
            <div className="flex lg:flex-row flex-col lg:gap-12 gap-5 lg:w-full lg:px-0 px-5">
                <MyFoodItemsWidget />
                <MyLastSearchWidget updateLastSearch={updateLastSearch} />
            </div>
            {
                lastSearch && (
                    <LastSearchMarketInsights lastSearch={lastSearch} />
                )
            }
            {
                lastSearch && (
                    <SearchStatistics lastSearch={lastSearch} />
                )
            }
        </div>
    )
}