import { useState, useEffect } from "react"
import api from "../libs/api";
import { Alert } from "../interfaces/alert.interface";
// import FeaturedAlerts from "./FeaturedAlerts";
import firebase from "firebase/app";
import "firebase/auth";
import { confirmDialog } from "primereact/confirmdialog";
import { captureException } from "@sentry/react";
import { formatNumber } from "../functions/formatNumber";
import UserAlertImage from "./UserAlertImage";

interface Props {
    setCurrentEditAlert: (id: string) => void;
}

export default function UserAlerts({ setCurrentEditAlert }: Props) {
    const [alerts, setAlerts] = useState<Alert[]>([]);
    const [trigger, setTrigger] = useState<string>();

    useEffect(() => {
        async function getAlerts() {
            try {
                const apiResponse = await api.get(`/alert/user/${firebase.auth().currentUser?.uid}`);
                if (apiResponse.status === 200) {
                    setAlerts(apiResponse.data as Alert[])
                }
            } catch (error) {
                captureException(error)
            }
        }
        getAlerts();
    }, [trigger])

    const deleteConfirm = (id: string) => {
        confirmDialog({
            message: 'Do you want to remove this item?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'bg-red-500 p-1 px-2 text-white',
            rejectClassName: 'bg-[#C7EBD7] p-1 px-2 text-black mr-3',
            accept: async () => {
                try {
                    const apiResponse = await api.delete(`/alert/${id}`);
                    if (apiResponse.status === 200) {
                        setTrigger(new Date().getTime().toString())
                    }
                } catch (error) {
                    captureException(error)
                }
            },
        });
    };

    if (alerts.length === 0) return (
        <div>
            <p>No price alert created yet</p>
        </div>
    )

    return (
        <div className="h-screen">
            <div className="flex flex-col pt-2 gap-5">
                {/* <FeaturedAlerts /> */}
                {/* <p className="text-xl font-['Manrope'] text-[#060606] font-bold mt-1">Current alerts</p>
                <hr className="my-1" /> */}
                {
                    alerts.map((alert) => (
                        <div key={alert._id} className="flex lg:flex-row flex-col w-full justify-between border-b-[1px] pb-4">
                            <div className="flex lg:flex-row flex-col gap-5">
                                <UserAlertImage commodityId={alert.commodity} />
                                <div className="flex flex-col gap-2">
                                    <p className="font-semibold">{alert.itemName}</p>
                                    <span className="text-sm">{alert.marketName}</span>
                                    <div className="flex flex-col gap-1">
                                        {alert.priceUpAmount && <p className="text-sm">Price change to <b><span className="text-[18px]">N{formatNumber(alert.priceUpAmount)}</span></b></p>}
                                        {/* {alert.priceDownAmount && <p className="text-green-500 text-sm">Price down to <b><span className="text-[18px]">N{formatNumber(alert.priceDownAmount)}</span></b></p>} */}
                                    </div>
                                </div>
                            </div>
                            <div className="flex lg:flex-row gap-2 lg:mt-0 mt-3">
                                <div
                                    onClick={() => setCurrentEditAlert(alert._id)}
                                    className="bg-[#E4EFE5] w-[64px] h-[39px] pt-2 rounded-md cursor-pointer">
                                    <p className="text-center">Edit</p>
                                </div>
                                <div onClick={() => deleteConfirm(alert._id)} className="bg-red-500 w-[64px] h-[39px] pt-2 rounded-md cursor-pointer">
                                    <p className="text-center text-white">Delete</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}