import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Landing from "./routes/Landing";
import Login from "./routes/Login";
import SignUp from "./routes/SignUp";
import ForgotPassword from "./routes/Forgotpassword";
import ViewCurrentPrices from "./routes/ViewCurrentPrices";
import Home from "./routes/Home";
import ProtectedRoute from "./features/ProtectedRoute";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import CategoryCurrentPrices from "./routes/CategoryCurrentPrices";
import FoodItemAlerts from "./routes/FoodItemAlerts";
import Compare from "./routes/Compare";
import MyFoodItems from "./routes/MyFoodItems";
import Infographic from "./routes/Infographic";
import { ConfirmDialog } from "primereact/confirmdialog";
import * as Sentry from "@sentry/react";
import ViewCurrentPricesGuest from "./features/ViewCurrentPricesGuest";
import CategoryCurrentPricesGuest from "./routes/CategoryCurrentPricesGuest";
import MyAccount from "./routes/MyAccount";
import ErrorBoundary from "./features/ErrorBoundary";
import Feedback from "./routes/Feedback";
import Markets from "./routes/Markets";

Sentry.init({
  dsn: "https://0838cd38e8b8648ef63ee9f2b9ecdca4@o4506447567781888.ingest.us.sentry.io/4507829188558848",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const router = createBrowserRouter([
    {
      path: "*",
      element: <Landing />,
    },
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/markets",
      element: <Markets />,
    },
    {
      path: "/home",
      element: <ProtectedRoute component={Home} />,
    },
    {
      path: "feedback",
      element: <ProtectedRoute component={Feedback} />,
    },
    {
      path: "/view-current-prices",
      element: <ProtectedRoute component={ViewCurrentPrices} />,
    },
    {
      path: "/view-prices-guest",
      element: <ViewCurrentPricesGuest />,
    },
    {
      path: "category/prices/:category?/:foodClass?/:item?/:itemState?/:market?",
      element: <ProtectedRoute component={CategoryCurrentPrices} />,
    },
    {
      path: "category-guest/prices/:category?/:foodClass?/:item?/:itemState?/:market?",
      element: <CategoryCurrentPricesGuest />,
    },
    {
      path: "alerts",
      element: <ProtectedRoute component={FoodItemAlerts} />,
    },
    {
      path: "account",
      element: <ProtectedRoute component={MyAccount} />,
    },
    {
      path: "compare",
      element: <ProtectedRoute component={Compare} />,
    },
    {
      path: "my-food-items",
      element: <ProtectedRoute component={MyFoodItems} />,
    },
    {
      path: "/infographic",
      element: <ProtectedRoute component={Infographic} />,
    },
  ]);
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <ErrorBoundary>
        <div className="w-[100vw] h-[100vh]">
          <ConfirmDialog />
          <RouterProvider router={router} />
        </div>
      </ErrorBoundary>
    </HelmetProvider>
  )
}

export default App
