import { useState, useEffect } from "react";
// import InsightMarketInfo from "./InsightMarketInfo";
import api from "../libs/api";
import firebase from "firebase/app";
import "firebase/auth";
import { formatNumber } from "../functions/formatNumber";
import { Skeleton } from 'primereact/skeleton';
import { captureException } from "@sentry/react";

interface PriceData {
  market: string;
  marketName: string;
  currentPrice: number;
  percentageChange: number;
  quarterlyChange: number;
  unit: string;
  marketState: string;
}

const LastSearchMarketInsights = ({ lastSearch }: { lastSearch?: string }) => {
  const [loading, toggleLoading] = useState(false);
  const [priceInsight, setPriceInsight] = useState<PriceData[]>();

  useEffect(() => {
    async function getInsights() {
      try {
        if (!lastSearch) return;
        toggleLoading(true);
        const apiResponse = await api.get(`price/insight/${firebase.auth().currentUser?.uid}`)
        if (apiResponse.status === 200) {
          const data = apiResponse.data as PriceData[];
          setPriceInsight(data);
          toggleLoading(false);
        }
        toggleLoading(false);
      } catch (error) {
        toggleLoading(false);
        captureException(error)
      }
    }
    void getInsights();
  }, [])

  if (!priceInsight) return null;

  return (
    <div className="bg-[#F7F8FA]">
      <div className="mt-10 px-5 py-10 rounded-md lg:bg-[#FBFFFA] lg:w-[800px] lg:mx-0">
        <h3 className="text-left mb-4 text-xl lg:mx-0 mx-5"><b>{lastSearch}</b>: Market Insights</h3>
        {
          loading ? (
            <div className="lg:w-[600px]">
              <Skeleton className="mb-2"></Skeleton>
              <Skeleton className="mb-2"></Skeleton>
              <Skeleton className="mb-2"></Skeleton>
              <Skeleton className="mb-2"></Skeleton>
            </div>
          ) : (
            <div className={`grid ${priceInsight?.length === 1 ? 'grid-cols-1' : 'grid-cols-2'} gap-3`}>
              {
                priceInsight?.filter((insight) => insight?.currentPrice > 0).map((insights) => {
                  const percentageStatColor = insights?.percentageChange < 0 ? '#FFACA3' : '#B2D185';
                  const quarterlyStatColor = insights?.quarterlyChange < 0 ? '#FFACA3' : '#B2D185';
                  return (
                    <div key={JSON.stringify(insights)} className="flex flex-col bg-[#F9F9F9] border rounded-md lg:p-3 p-2">
                      <div className="flex flex-col">
                        <p className="text-sm text-[#A9ACA8]">{insights?.marketState}</p>
                        <p className="text-[#1B1C1B] font-semibold">{insights?.marketName}</p>
                      </div>
                      <p>{insights?.unit}</p>
                      <div className="lg:flex lg:flex-row  gap-3 flex-col">
                        <div>
                          <small className="text-[10px]">Current price</small>
                          <div className={`bg-[#E1E3E1] w-fit px-2 rounded-md`}>
                            <p>N{`${formatNumber(Number(insights?.currentPrice?.toFixed(1)))}`}</p>
                          </div>
                        </div>
                        <div>
                          <small className="text-[10px]">% Change</small>
                          <div className={`bg-[${percentageStatColor}] border w-fit px-2 rounded-md`}>
                            <p>{`${insights?.percentageChange?.toFixed(2)}`}%</p>
                          </div>
                        </div>
                        <div>
                          <small className="text-[10px]">% Quarterly change</small>
                          <div className={`bg-[${quarterlyStatColor}] w-fit px-2 rounded-md`}>
                            <p>{`${insights?.quarterlyChange?.toFixed(2)}%`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default LastSearchMarketInsights;
