import { Avatar } from "primereact/avatar";
import firebase from "firebase/app";
import { useNavigate } from "react-router-dom";
import "firebase/auth";

export default function AuthHeader() {
    const navigate = useNavigate();
    return (
        <div className="border border-l-0 border-b-1 border-[#ecedef] bg-white flex flex-row p-4">
            <div onClick={() => navigate('/account')} className="flex flex-row ml-auto cursor-pointer">
                <Avatar label={`${firebase.auth().currentUser?.displayName?.slice(0, 1)}`} style={{ backgroundColor: '#22c55e', color: '#ffffff' }} />
                <p className="ml-3 mt-1">{firebase.auth().currentUser?.displayName}</p>
            </div>
        </div>
    )
}