import { useState, useEffect } from 'react';
import SearchIcon from '../../assets/searchIcon.svg'
import { useNavigate } from 'react-router-dom';
import { Search } from '../../interfaces/search.interface';
import firebase from "firebase/app";
import "firebase/auth";
import api from '../../libs/api';
import { formatNumber } from '../../functions/formatNumber';
import { captureException } from '@sentry/react';
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaArrowsUpDown } from "react-icons/fa6";

export default function MyLastSearchWidget({ updateLastSearch }: { updateLastSearch: (item: string) => void }) {
    const navigate = useNavigate();
    const [lastSearch, setLastSearch] = useState<Search | undefined>(undefined);
    const [lastSearchPrice, setLastSearchPrice] = useState(0);
    const [unit, setUnit] = useState<string>();

    useEffect(() => {
        async function getUserLastSearch() {
            try {
                const apiResponse = await api.get(`search/last/${firebase.auth().currentUser?.uid}`);
                if (apiResponse.status === 200) {
                    const lastSearchData = apiResponse.data.lastSearch as Search;
                    const lastSearchPrice = apiResponse.data.latestPrice;
                    const priceUnit = apiResponse.data.unit;
                    setLastSearch(lastSearchData);
                    setLastSearchPrice(lastSearchPrice);
                    updateLastSearch(lastSearchData.item);
                    setUnit(priceUnit);
                }
            } catch (error) {
                captureException(error);
            }
        }
        getUserLastSearch();
    }, [])

    if (!lastSearch || !lastSearchPrice) {
        return (
            <div className="lg:min-w-[380px] lg:max-w-5xl border rounded shadow">
            <div className="flex flex-row justify-between p-4 bg-[#FBFFFA] h-[121px]">
                <div>
                    <p className='text-xl'>No search yet</p>
                </div>
                <img src={SearchIcon} />
            </div>
            <div className='flex flex-row justify-between border-t rounded bg-[#F9F9F9] py-0 px-4 h-[58px]'>
                <p onClick={() => navigate('/view-current-prices')} className='text-sm font-semibold cursor-pointer mt-4'>Search now</p>
            </div>
        </div>
        )
    }

    const PriceIcon = ({lastSearchPrice, currentPrice}: {lastSearchPrice?: number; currentPrice?: number}) => {
        if(!lastSearchPrice || !currentPrice) return null;
        if(lastSearchPrice > currentPrice) {
            return (
                <FaLongArrowAltDown className='mt-1 text-green-500' />
            )
        }
        if(lastSearchPrice < currentPrice) {
            return (
                <FaLongArrowAltUp className='mt-1 text-red-500' />
            )
        }
        return (
            <FaArrowsUpDown className='mt-1' />
        )
    }

    return (
        <div className="lg:min-w-[380px] lg:max-w-5xl border rounded shadow">
            <div className="flex flex-row justify-between p-4 bg-[#FBFFFA] h-[121px]">
                <div>
                    <p className='text-xs'>My last search</p>
                    <p className='text-xl font-semibold my-2'>{lastSearch?.item}</p>
                    <p className='text-xs'>{unit}</p>
                    <p className='text-xs'>N{formatNumber(Number(lastSearch?.price.toFixed()))}</p>
                </div>
                <img src={SearchIcon} />
            </div>
            <div className='flex flex-row justify-between border-t rounded bg-[#F9F9F9] py-0 px-4 h-[58px]'>
                {
                    lastSearchPrice && (
                        <div>
                            <small>Current price</small>
                            <div className='rounded-md flex flex-row'>
                                <PriceIcon lastSearchPrice={lastSearch?.price} currentPrice={lastSearchPrice} />
                                <p className='font-semibold'>
                                    N{formatNumber(Number(lastSearchPrice))}</p>
                            </div>
                        </div>
                    )
                }
                <p onClick={() => navigate('/view-current-prices')} className='text-sm font-semibold cursor-pointer mt-4'>View more</p>
            </div>
        </div>
    )
}