import { useState, useEffect } from "react";
import api from "../libs/api";
import { useNavigate } from "react-router-dom";
import foodIcon from '../assets/food.svg';
import TubersIcon from '../assets/yam.png';
import SoupIcon from '../assets/soup.png';
import GrainsIcon from '../assets/grains.png';
import { captureException } from "@sentry/react";
import { PiPiggyBankThin } from "react-icons/pi";
import { CiDroplet } from "react-icons/ci";
import { PiFishThin } from "react-icons/pi";
import { GiKiwiFruit } from "react-icons/gi";

import { IconType } from "react-icons/lib";
import { analytics } from "../firebase-config";

const categoryIconMap: Record<string, IconType | string> = {
    'SEA FOOD': PiFishThin,
    'POULTRY': PiPiggyBankThin,
    'OIL': CiDroplet,
    'BULB&VEGETABLES': GiKiwiFruit,
};

export default function ViewCurrentPrices() {
    analytics.logEvent('page_view', {
        page_title: 'view current prices'
    });
    const navigate = useNavigate();
    const [categoryPrices, setCategoryPrices] = useState<{ category: string; count: number }[]>([]);

    const gotoPrices = (category: string) => {
        navigate(`/category/prices/${category}`);
    };

    useEffect(() => {
        async function getCategoryPrices() {
            try {
                const apiResponse = await api.get('commodity/category-counts');
                if (apiResponse.status === 200) {
                    setCategoryPrices(apiResponse.data as { category: string; count: number }[]);
                }
            } catch (error) {
                captureException(error);
            }
        }
        getCategoryPrices();
    }, []);

    const CategoryItem = ({ category, count }: { category: string, count: number }) => {
        const IconComponent = categoryIconMap[category] || null;
        let image = foodIcon;
        if (!IconComponent) {
            if (category === 'TUBERS') image = TubersIcon;
            if (category === 'SOUP INGREDIENTS') image = SoupIcon;
            if (category === 'GRAINS&LEGUMES') image = GrainsIcon;
        }
        return (
            <div
                onClick={() => gotoPrices(category)}
                className="border bg-white dark:bg-gray-800 w-full rounded-md shadow flex flex-col p-6 cursor-pointer hover:shadow-lg transition-shadow"
            >
                {IconComponent ? (
                    <IconComponent size={72} className="text-[#121737] dark:text-gray-100" />
                ) : (
                    <img src={image} width={72} height={71} alt="food-icon" />
                )}
                <p className="font-semibold mt-4 text-[#121737] dark:text-white break-words">{category}</p>
                <p className="mt-2 text-[#A0A2AF] dark:text-gray-400">{count} items</p>
            </div>
        );
    };

    return (
        <div className="min-h-screen lg:px-10 px-5 pb-5 bg-[#F7F8FA] dark:bg-gray-900">
            <div className="mt-16">
                <p className="text-[#121737] dark:text-white text-2xl font-bold font-['Work Sans'] leading-normal">View current prices</p>
                <p className="mt-2 font-['Manrope'] text-[#060606] dark:text-gray-300 mb-10">Find any food item you want</p>
            </div>
            <div className="lg:grid lg:grid-cols-4 flex flex-col gap-5">
                {categoryPrices.map(({ category, count }) => (
                    <CategoryItem key={JSON.stringify(category)} category={category} count={count} />
                ))}
            </div>
        </div>
    );
}
