import { useState, useEffect } from "react";
import api from "../libs/api";
import { useParams, useNavigate } from "react-router-dom";
import MarketLocationPrice from "../features/MarketLocationPrice";
import { Dropdown } from "primereact/dropdown";
import { Market } from "../interfaces/market.interface";
import { captureException } from "@sentry/react";
import { Commodity } from "../interfaces/commodity.interface";
import { analytics } from "../firebase-config";

export default function CategoryCurrentPrices() {
    analytics.logEvent('page_view', {
        page_title: 'category prices'
    });
    const navigate = useNavigate();
    const { category, foodClass, item, itemState, market } = useParams();
    const [commodities, setCommodities] = useState<Commodity[]>([]);
    const [foodClasses, setFoodClasses] = useState<string[]>([]);
    const [selectedFoodClass, setSelectedFoodClass] = useState<string | undefined>(foodClass);
    const [itemsCommoditiesByClass, setItemsCommoditiesByClass] = useState<string[]>([]);
    const [selectedItemCommodity, setSelectedItemCommodity] = useState<string | undefined>(item);

    const [states, setStates] = useState<(string | undefined)[]>([]);
    const [selectedState, setSelectedState] = useState<string | undefined>(itemState);
    const [marketLocations, setMarketLocations] = useState<Market[]>([]);
    const [marketsInState, setMarketsInState] = useState<Market[]>([]);
    const [showMarket, setShowMarket] = useState(false);
    const [showState, setShowState] = useState(false);
    const [selectedMarketLocation, setSelectedMarketLocation] = useState<string | undefined>(market);

    useEffect(() => {
        async function getCommodities() {
            try {
                setMarketsInState([]);
                const apiResponse = await api.get('commodity/all');
                if (apiResponse.status === 200) {
                    const commodityList = apiResponse.data as Commodity[];
                    const sortedItems = [...commodityList].sort((a, b) => a.item.localeCompare(b.item));
                    const commodityListForCategory = sortedItems.filter((comm) => comm.category === category);
                    const commodityFoodClasses = getUniqueFoodClasses(commodityListForCategory);
                    setCommodities(commodityListForCategory);
                    setFoodClasses(commodityFoodClasses);
                }
            } catch (error) {
                captureException(error);
            }
        }
        void getCommodities();
    }, [category]);

    useEffect(() => {
        async function populateFoodItemsFromFoodClass() {
            try {
                if (!selectedFoodClass) return;
                setShowMarket(false);
                setShowState(false);
                setMarketLocations([]);
                setStates([]);
                const foodItems = commodities.filter((commodity) => commodity.foodClass === selectedFoodClass);
                setItemsCommoditiesByClass(foodItems.map((item) => item.item));
            } catch (error) {
                captureException(error);
            }
        }
        void populateFoodItemsFromFoodClass();
    }, [commodities, selectedFoodClass]);

    useEffect(() => {
        async function getMarketLocations() {
            try {
                if (!selectedItemCommodity) return;
                setShowMarket(false);
                setShowState(false);
                setMarketLocations([]);
                setStates([]);
                const apiResponse = await api.get(`commodity/category-items/market/${selectedItemCommodity}`);
                if (apiResponse.status === 200) {
                    const markets = apiResponse.data as Market[];
                    const sortedItems = [...markets].sort((a, b) => a.name.localeCompare(b.name));
                    setMarketsInState([]);
                    setMarketLocations(sortedItems);
                    const itemStates = sortedItems.map((market) => market.state);
                    setStates(Array.from(new Set(itemStates)));
                    setShowState(true);
                }
            } catch (error) {
                captureException(error);
            }
        }
        getMarketLocations();
    }, [category, selectedItemCommodity]);

    useEffect(() => {
        async function getMarketsInState() {
            if (!selectedState) return;
            setShowMarket(false);
            const marketLocationsInState = marketLocations.filter((m) => m.state === selectedState);
            setMarketsInState(marketLocationsInState);
            setShowMarket(true);
        }
        getMarketsInState();
    }, [marketLocations, selectedState]);

    const handleFoodClassChange = (newFoodClass: string) => {
        setSelectedFoodClass(newFoodClass);
        navigate(`/category/prices/${category}/${newFoodClass}/${undefined}/${undefined}/${undefined}`);
    };

    const handleFoodItemChange = (foodItem: string) => {
        setSelectedItemCommodity(foodItem);
        navigate(`/category/prices/${category}/${selectedFoodClass}/${foodItem}/${undefined}/${undefined}`);
    };

    const handleStateChange = (newState: string) => {
        setSelectedState(newState);
        navigate(`/category/prices/${category}/${selectedFoodClass}/${selectedItemCommodity}/${newState}/${undefined}`);
    };

    const handleMarketChange = (newMarket: string) => {
        setSelectedMarketLocation(newMarket);
        navigate(`/category/prices/${category}/${selectedFoodClass}/${selectedItemCommodity}/${selectedState}/${newMarket}`);
    };

    const shareLink = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: `Check out the price details for ${selectedItemCommodity}`,
                    text: `Hey, check out the price details for ${selectedItemCommodity} at ${selectedMarketLocation}`,
                    url: window.location.href,
                });
            } catch (error) {
                captureException(error);
            }
        } else {
            alert('Web Share is not supported in this browser. \nYou can copy the link and share manually.');
        }
    };

    function getUniqueFoodClasses(commodities: Commodity[]): string[] {
        const uniqueFoodClasses = new Set<string>();
        commodities.forEach(commodity => {
            if (commodity.foodClass) {
                uniqueFoodClasses.add(commodity.foodClass);
            }
        });
        return Array.from(uniqueFoodClasses);
    }

    return (
        <div className="min-h-screen px-4 py-8 bg-white dark:bg-gray-900">
            <div className="mb-8">
                <p className="text-2xl font-bold text-[#121737] dark:text-white font-['Work Sans'] leading-normal">
                    View Current Prices
                </p>
            </div>
            <hr className="mb-10 border-gray-200 dark:border-gray-700" />
            <div className="flex flex-col gap-5">
                <Dropdown
                    value={selectedFoodClass}
                    onChange={(e) => handleFoodClassChange(e.value)}
                    options={foodClasses}
                    placeholder="Select Food Class"
                    className="w-full max-w-full border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                    // autoWidth={false}
                />
                {selectedFoodClass && (
                    <Dropdown
                        value={selectedItemCommodity}
                        onChange={(e) => handleFoodItemChange(e.value)}
                        options={itemsCommoditiesByClass}
                        placeholder="Select Food Item"
                        className="w-full max-w-full border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                        // autoWidth={false}
                    />
                )}
                {states.length > 0 && showState && (
                    <Dropdown
                        value={selectedState}
                        onChange={(e) => {
                            setSelectedMarketLocation(undefined);
                            handleStateChange(e.value);
                        }}
                        options={states.sort((a, b) => `${a}`.localeCompare(`${b}`))}
                        placeholder="Select State"
                        className="w-full max-w-full border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                        // autoWidth={false}
                    />
                )}
                {marketsInState.length > 0 && showMarket && (
                    <Dropdown
                        value={selectedMarketLocation}
                        onChange={(e) => handleMarketChange(e.value)}
                        options={marketsInState.map((m) => m.name)}
                        placeholder="Select Market"
                        className="w-full max-w-full border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                        // autoWidth={false}
                    />
                )}
                {showMarket && selectedMarketLocation && selectedMarketLocation !== 'undefined' && (
                    <MarketLocationPrice
                        item={selectedItemCommodity}
                        marketLocation={marketLocations.find((m) => m.name === selectedMarketLocation)?._id}
                        state={selectedState}
                        marketName={selectedMarketLocation}
                        marketId={marketLocations.find((m) => m.name === selectedMarketLocation)?._id}
                        onShareClick={shareLink}
                    />
                )}
            </div>
        </div>
    );
}
