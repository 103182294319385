import Input from "../components/ui/Input";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import firebase from "firebase/app";
import "firebase/auth";
import { captureException } from "@sentry/react";
import { analytics } from "../firebase-config";
import { Helmet } from "react-helmet-async";

export default function ForgotPassword() {
    analytics.logEvent('page_view', {
        page_title: 'forgot password'
    });
    const toast = useRef<Toast>(null);
    const [email, setEmail] = useState('');

    async function createResetProcess() {
        firebase.auth().sendPasswordResetEmail(email).then(() => {
            toast.current?.show({ severity: 'success', summary: `Password reset instructions sent to ${email}.` })
        }).catch((error) => {
            captureException(error);
            toast.current?.show({ severity: 'error', summary: `Unable to send instructions sent to ${email}.` })
        })
    }

    return (
        <div className="flex flex-col h-screen font-worksans">
            <Helmet>
                <title>Forgotten password | Arket data</title>
                <meta name='description' content='Recover your account.' />
                <meta
                    name="keywords"
                    content="Arket password, Arket forgot password, Arketdata password reset, Arket data, Arket"
                />
            </Helmet>
            <Toast ref={toast} />
            <div className="flex-1 flex items-center justify-center bg-[#F9FFF8]">
                <div className="w-full max-w-lg p-8">
                    <form>
                        <h2 className="text-center text-3xl mb-5 font-semibold font-worksans">Reset your password</h2>
                        <p className="text-center text-[#8A92A6] font-worksans mb-8">We will send instructions on how to reset your password to your email address.</p>
                        <div className="mb-4">
                            <Input placeholder="your@email.com" type="text" label="Email" onChange={setEmail} name="email" extraStyle="h-[44px]" />
                        </div>
                        <div className="flex items-center justify-between">
                            <button onClick={createResetProcess} className="bg-[#027432] w-full text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline font-bold" type="button">
                                Send instructions
                            </button>
                        </div>
                        <div className="flex flex-col mt-8">
                            <p className="text-center mt-5">Already have an account? <a href='/login' className="text-[#3A57E8] cursor-pointer">Click here to sign in</a></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}