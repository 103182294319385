import ArketLogo from '../../assets/svgs/arktetLogo.svg';
// import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
// import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
// import { IoLogoWhatsapp } from "react-icons/io";
export default function Footer() {
    return (
        <div>
            <div className="flex flex-row justify-center mt-5">
                <img src={ArketLogo} className='self-center' />
            </div>
            <div>
                {/* <p className='text-center my-5'>Address: 548 Market St. Suite 95149 San Francisco, California</p> */}
                <div className='flex flex-row justify-center gap-5 mt-5'>
                    {/* <FaFacebook className='cursor-pointer' />
                    <AiFillInstagram className='cursor-pointer' />
                    <FaLinkedin className='cursor-pointer' />
                    <IoLogoWhatsapp className='cursor-pointer' /> */}
                    <FaTiktok onClick={() => {
                        window.open('https://tiktok.com/@arketdata', '_blank');
                    }} className='cursor-pointer' />
                    <FaXTwitter onClick={() => {
                        window.open('https://x.com/arketdata_ng?t=RvMVutC7UZ3NHjwDjlr_mA&s=09', '_blank')
                    }} className='cursor-pointer' />
                    <AiFillInstagram onClick={() => {
                        window.open('https://www.instagram.com/arketdata_ng', '_blank')
                    }} className='cursor-pointer' />
                </div>
            </div>
            <div className="flex flex-row justify-center mt-5 gap-5">
                <small className='cursor-pointer' onClick={() => {
                    window.open('https://res.cloudinary.com/appdata-rock/image/upload/v1726166950/ARKETDATA-PRIVACY_POLICY_uxrkcd.pdf')
                }}>Privacy policy</small>
                <small className='cursor-pointer' onClick={() => {
                    window.open('https://res.cloudinary.com/appdata-rock/image/upload/v1726166949/ARKETDATA-TERMS_AND_CONDITIONS_wpzqmv.pdf')
                }}>Terms & Conditions</small>
            </div>
        </div>
    )
}