import { useLocation, useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();

    const navigateToPage = async () => {
        // navigate('/view-current-prices');
        const userId = await firebase.auth().currentUser?.uid;
        if (userId) {
            navigate('/view-current-prices');
        } else {
            navigate('/view-prices-guest');
        }
    }
    return (
        <div className="flex flex-row border-b border-white h-[72px] pt-3">
            <div className="lg:ml-auto flex flex-row gap-5 lg:pr-10 pr-5">
                {
                    location.pathname === '/markets' ? (
                        <a href="/">
                            <p className="font-bold text-white mt-3">Home</p>
                        </a>
                    ) : (
                        <a href="/markets">
                            <p className="font-bold text-white mt-3">Market locations</p>
                        </a>
                    )
                }
                {
                    !firebase.auth().currentUser?.uid && (
                        <a href="/signup">
                            <p className="font-bold text-white mt-3">My account</p>
                        </a>
                    )
                }
                <button onClick={navigateToPage} className="px-3 h-fit py-2 bg-green-600 rounded text-white font-bold">
                    View current prices
                </button>
            </div>
        </div>
    )
}