import Header from "../components/feature/Header";
// import DiscoverTrending from "../components/feature/DiscoverTrending";
import TrendingFoodItems from "../components/feature/TrendingFoodItems";
import background from "../assets/LandingImage.jpeg";
// import { CiSearch } from "react-icons/ci";
import LandingImage1 from '../assets/LandingImage1.jpeg';
import LandingImage2 from '../assets/LandingImage2.jpg';
import LandingImage3 from '../assets/LandingImage3.jpg';
import Footer from "../components/feature/Footer";
import MobileHeader from "../components/feature/MobileHeader";
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firebase-firestore";
import { analytics } from "../firebase-config";


export default function Landing() {
    analytics.logEvent('page_view', {
        page_title: 'landing'
    });
    const navigate = useNavigate();
    const gotoHompage = async () => {
        const userId = await firebase.auth().currentUser?.uid;
        if (userId) {
            navigate('/view-current-prices');
        } else {
            localStorage.setItem('pendingNavigation', '/view-current-prices');
            navigate('/view-prices-guest');
        }
    }
    return (
        <div className="h-screen w-full font-worksans">
            <Helmet>
                <title>Historical and real-time data on food prices | Arket data</title>
                <meta
                    name="description"
                    content="Get access to historical and real-time data on food prices, availability, and trends."
                />
                <meta
                    name="keywords"
                    content="Food prices, Arket, Arketdata, Arket data, Market, Rice"
                />
            </Helmet>
            <div style={{ backgroundImage: `url(${background})`, height: 770 }}>
                <div className="lg:block hidden">
                    <Header />
                </div>
                <div className="lg:hidden block">
                    <MobileHeader />
                </div>
                <div className="pt-56 lg:pl-32 pl-5">
                    <div className="lg:w-96 h-80 flex-col justify-start items-start gap-6 inline-flex">
                        <div className="flex-col justify-start items-start gap-3 flex">
                            <div className="">
                                <p className=" text-white text-5xl font-bold font-['Work Sans']">Understand the <br /> market, make better</p>
                                <p className=" text-white text-5xl font-bold font-['Work Sans']">decisions</p>
                            </div>
                            <div className="justify-center items-center inline-flex">
                                <div className="lg:w-96 text-white text-lg font-medium font-['Work Sans'] leading-normal">Get access to historical and real-time data on food prices, availability, and trends</div>
                            </div>
                        </div>
                        <div className="flex flex-row rounded-md lg:w-full py-1 pr-1">
                            <button onClick={gotoHompage} className="px-3 py-2 bg-green-600 rounded text-white font-bold">
                                View current prices
                            </button>
                            {/* <CiSearch className="mt-3 mr-2" />
                            <input placeholder="Search for a food item" className=" outline-none border-0" />
                            <button className="px-3 py-2 bg-green-600 rounded ml-auto text-white font-bold">
                                Try for free
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="py-10 bg-[#F6FAF7]">
                <DiscoverTrending />
            </div> */}
            <div className="py-10">
                <TrendingFoodItems />
            </div>
            <div className="py-16 bg-[#F6FAF7]">
                <div>
                    <div className="lg:p-10 p-16 pb-0">
                        <p className="text-center text-slate-900 text-3xl font-bold font-['Work Sans'] leading-10">
                            Powerful analytics at your fingertips
                        </p>
                        <p className="text-center text-slate-600 font-medium font-['Work Sans'] leading-normal mt-3 mb-5">
                            Our platform provides you with a comprehensive view of food prices across different markets in Nigeria, so you can make better decisions and manage your spending.
                        </p>
                        <p className="text-center text-slate-600 font-medium font-['Work Sans'] leading-normal mt-3 mb-5">
                            Prices on this website might vary plus or minus 10% depending on your individual negotiating power.
                        </p>
                        <p className="text-center text-slate-600 font-medium font-['Work Sans'] leading-normal mt-3 mb-5">
                            Images on this platform are only for representation only and are not actual images from today’s market visits.
                        </p>
                    </div>
                    <div className="flex lg:grid lg:grid-cols-3 gap-5 flex-col justify-center mt-10 lg:p-10 p-16">
                        <div className="flex flex-col gap-1">
                            <img src={LandingImage1} alt="landing-image-1" className="lg:h-80" />
                            <p className="text-sm mt-2 dark:text-slate-600"><b className="text-xl">View Current Prices</b> (Just pick your favorite food item, choose the State in Nigeria where you want to check the price, and then select the specific market within that State. Voila! You'll instantly see the current price of your selected food item.)</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <img src={LandingImage2} alt="landing-image-2" className="lg:h-80" />
                            <p className="text-sm mt-2 dark:text-slate-600"><b className="text-xl">Create Your Food Items List and View Your Prices Without Searching All the Time.</b> (Add your favorite food items and top markets to your personal List, and watch as prices shift right before your eyes! With Arketdata, tracking price changes has never been simpler. Just create your custom List and stay updated with every login. Effortless price monitoring is now at your fingertips!.)</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <img src={LandingImage3} alt="landing-image-3" className="lg:h-80" />
                            <p className="text-sm mt-2 dark:text-slate-600"><b className="text-xl">Price Alerts</b> (Stay ahead of price changes with ease! Head to the Price Alerts section, choose your favorite food item and set your ideal price. We'll send you an email the moment your selected item hits your target price in your chosen market. Get ready to score great deals without missing a beat!.)</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-16 bg-[#123713]">
                <div className="lg:p-10 p-16 pb-0">
                    <p className="text-center text-white text-3xl font-bold font-['Work Sans'] leading-10">
                        Find latest food prices before everyone else
                    </p>
                    <p className="text-center text-white font-medium font-['Work Sans'] leading-normal mt-3 mb-5">
                        While you are here, create an account and sign in to get the latest insights and trends on food prices and most searched commodities.
                    </p>
                </div>
                <div className="flex flex-row justify-center mt-5">
                    <button onClick={() => navigate('/signup')} className="px-3 py-2 bg-green-600 rounded text-white font-bold">
                        Get Started
                    </button>
                </div>
            </div>
            <div className="py-16">
                <Footer />
            </div>
        </div>
    )
}