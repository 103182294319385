import Input from "../components/ui/Input";
import { useRef, useState } from "react";
import { Checkbox } from "primereact/checkbox";
// import { FcGoogle } from "react-icons/fc";
// import { FaFacebook } from "react-icons/fa";
import ArketLogo from '../assets/arketLogoWhite.png';
import { Toast } from 'primereact/toast';
import { useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firebase-firestore";
import { captureException } from "@sentry/react";
import { Password } from "primereact/password";
import { analytics } from "../firebase-config";
import { Helmet } from "react-helmet-async";

export default function SignUp() {
    analytics.logEvent('page_view', {
        page_title: 'sign up'
    });
    const navigate = useNavigate();
    const toast = useRef<Toast>(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [rememberPassword, setRememberPassword] = useState<boolean | undefined>(false);

    async function createAccount() {
        try {
            console.log({ firstName, lastName, password, confirmPassword, email })
            if (!firstName || !lastName || !password || !confirmPassword || !email) {
                toast.current?.show({ severity: "error", summary: 'Incomplete details', detail: 'Please fill the form completelyyyy.' });
                return;
            }
            if (password !== confirmPassword) {
                toast.current?.show({ severity: "error", summary: 'Password mismatch', detail: 'Please check the password and confirm password fields.' });
                return;
            }
            toast.current?.show({ severity: 'info', summary: 'Attempting to create your account...' })
            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(async (user) => {
                    firebase.auth().currentUser?.updateProfile({
                        displayName: `${firstName} ${lastName}`,
                    }).then(() => {
                        firebase.firestore().collection('users').doc(user.user?.uid).set({
                            firstName, lastName, email, joined: new Date()
                        }).then(() => {
                            const pendingNav = localStorage.getItem('pendingNavigation');
                            localStorage.removeItem('pendingNavigation');
                            localStorage.removeItem('viewCount');
                            if (pendingNav) {
                                navigate(pendingNav);
                            } else {
                                navigate('/home');
                            }
                        });
                    })
                }).catch((error) => {
                    captureException(error);
                    const errorMessage = `${error.response.data.message}` || 'Unknown error';
                    toast.current?.show({ severity: "error", summary: 'Error occured', detail: errorMessage });
                })
        } catch (error) {
            toast.current?.show({ severity: "error", summary: 'Account creation error', detail: 'Unable to create your account.\n Please try again or reach out to support.' });
        }
    }
    return (
        <div className="flex flex-col md:flex-row h-screen font-worksans">
            <Helmet>
                <title>Create account | Arket data</title>
                <meta name='description' content='Create an account on Arketdata.' />
                <meta
                    name="keywords"
                    content="Arket signup, Arketdata create account, Arket data, Arket"
                />
            </Helmet>
            <Toast ref={toast} />
            <div className="md:flex-1 flex items-center justify-center bg-[#012D13] hidden md:block">
                <div className="w-full max-w-lg p-8 pt-64 pl-28">
                    <img src={ArketLogo} alt="Side Panel" />
                    <p className="font-semibold text-white text-[36px] mt-5">Register for Free to Get Started.</p>
                    <p className="text-white mt-4">Get instant access to food item trends, markets, and tomorrow's products at least 6 months before they take off.</p>
                    <div className="flex flex-col mt-52">
                        <p className="font-semibold text-white">Already registered?</p>
                        <a href="/login">
                            <button className="bg-[#027432] w-fit text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline font-bold mt-2" type="button">
                                Sign in here
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex-1 flex items-center justify-center bg-[#F9FFF8]">
                <div className="w-full max-w-lg p-8">
                    <h2 className="text-center text-3xl mb-5 font-semibold font-worksans">Create your account</h2>
                    <p className="text-center text-[#8A92A6] font-worksans mb-12">Create an account to view and manage your customization alerts and reports.</p>
                    <div className="flex lg:flex-row flex-col justify-between mb-4">
                        <Input placeholder="your first name" type="text" label="First name" onChange={setFirstName} name="first name" extraStyle="h-[44px] lg:mb-0 mb-4 dark:bg-white dark:text-black" />
                        <Input placeholder="your last name" type="text" label="Last name" onChange={setLastName} name="last name" extraStyle="h-[44px] dark:bg-white dark:text-black" />
                    </div>
                    <div className="mb-4">
                        <Input placeholder="your@email.com" type="text" label="Email" onChange={setEmail} name="email" extraStyle="h-[44px] dark:bg-white dark:text-black" />
                    </div>
                    <div className="flex flex-col justify-between mb-5 gap-6">
                        <div>
                            <label className="block text-[#8A92A6] text-sm font-bold mb-2" htmlFor={'password'}>Password</label>
                            <Password placeholder="**********" feedback={false} inputId="password" className="lg:w-[445px] w-full" inputClassName="shadow appearance-none border rounded lg:w-[445px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[44px] dark:bg-white dark:text-black" value={password} onChange={(e) => setPassword(e.target.value)} toggleMask />
                        </div>
                        <div>
                            <label className="block text-[#8A92A6] text-sm font-bold mb-2" htmlFor={'confirm-password'}>Confirm password</label>
                            <Password placeholder="**********" feedback={false} inputId="confirm-password" className="lg:w-[445px] w-full" inputClassName="shadow appearance-none border rounded lg:w-[445px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[44px] dark:bg-white dark:text-black" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} toggleMask />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between mb-10">
                        <div>
                            <Checkbox className="border border-1 rounded-md" inputId="rememberPassword" onChange={e => setRememberPassword(e.checked)} checked={rememberPassword ?? false}></Checkbox>
                            <label onClick={() => {
                                window.open('https://res.cloudinary.com/appdata-rock/image/upload/v1726166949/ARKETDATA-TERMS_AND_CONDITIONS_wpzqmv.pdf')
                            }} htmlFor="rememberPassword" className="ml-2 underline cursor-pointer">I agree with the terms and conditions</label>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <button onClick={createAccount} className="bg-[#027432] w-full text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline font-bold" type="button">
                            Sign up
                        </button>
                    </div>
                    <div className="flex flex-col mt-5">
                        {/* <p className="text-center">Or sign up with other account?</p>
                        <div className="flex flex-row justify-center mt-5 gap-3">
                            <FcGoogle className="text-3xl cursor-pointer" />
                            <FaFacebook className="text-3xl cursor-pointer" />
                        </div> */}
                        <p className="text-center mt-5 dark:text-slate-600">Already have an account? <a href='/login' className="text-[#3A57E8] cursor-pointer">Click here to sign in</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}