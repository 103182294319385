import { useState, useEffect, useRef } from "react";
import api from "../libs/api";
import { Skeleton } from 'primereact/skeleton';
import { PlusOutlined } from "@ant-design/icons";
import { formatNumber } from "../functions/formatNumber";
import firebase from "firebase/app";
import { Toast } from "primereact/toast";
import "firebase/auth";
import { captureException } from "@sentry/react";

interface CommodityData {
    commodityId: string;
    item: string;
    category: string;
    currentPrice: number;
    percentageChange: number;
    unit: string;
    marketName: string;
    state: string;
    commodityName: string;
    imageUrl?: string;
    marketId: string;
}

export default function SearchStatistics({ lastSearch }: { lastSearch?: string }) {
    const toast = useRef<Toast>(null);
    const [data, setData] = useState<CommodityData[]>();
    const [loading, toggleLoading] = useState(false);

    useEffect(() => {
        async function getInsights() {
            try {
                if (!lastSearch) return;
                toggleLoading(true);
                const apiResponse = await api.get(`price/related/${lastSearch}`)
                if (apiResponse.status === 200) {
                    const apiData = apiResponse.data as CommodityData[];
                    setData(apiData);
                    toggleLoading(false);
                }
                toggleLoading(false);
            } catch (error) {
                toggleLoading(false);
                captureException(error)
            }
        }
        void getInsights();
    }, [lastSearch])

    async function addItemToFoodItem(marketName: string, state: string, commodityName: string, marketId: string, currentPrice: number) {
        try {
            const apiResponse = await api.post('/myfooditems/new', {
                marketName,
                state,
                commodityName,
                marketId: marketId,
                userId: firebase.auth().currentUser?.uid,
                currentPrice,
            })
            if ([200, 201].includes(apiResponse.status)) {
                toast.current?.show({ severity: "success", summary: '', detail: `Added ${commodityName} to your list.` });
            }
        } catch (error) {
            captureException(error)
        }
    }

    return (
        <div className="bg-[#F7F8FA] lg:bg-transparent mt-10 pt-10 lg:pt-0">
            <Toast ref={toast} />
            <h3 className="text-left mb-4 text-xl lg:mx-0 mx-5">What others are searching</h3>
            {
                loading ? (
                    <div className="lg:w-[600px]">
                        <Skeleton className="mb-2"></Skeleton>
                        <Skeleton className="mb-2"></Skeleton>
                        <Skeleton className="mb-2"></Skeleton>
                        <Skeleton className="mb-2"></Skeleton>
                    </div>
                ) : (
                    <div className="lg:w-[800px] rounded bg-[#FBFFFA] p-5">
                        {
                            data?.filter((d) => d?.currentPrice > 0).map((item) => {
                                const percentageStatColor = item?.percentageChange < 0 ? '#FFACA3' : '#B2D185';
                                if (item.currentPrice === 0) {
                                    return <div />
                                }
                                return (
                                    <div key={item.commodityId} className="flex flex-col gap-4">
                                        <div className="flex flex-row justify-between">
                                            <div className="flex flex-row gap-5">
                                                <img src={item?.imageUrl} className="h-[100px] w-[100px] mt-3 rounded-full object-contain" />
                                                <div>
                                                    <p className="text-xl mt-3 mb-1">{item?.item}</p>
                                                    <p className="mb-1">{item?.unit}</p>
                                                    <div className="flex flex-row gap-3">
                                                        <div>
                                                            <small className="text-[10px]">Current price</small>
                                                            <div className={`bg-[#B2D185] w-fit px-2 rounded-md`}>
                                                                <p>{`N${formatNumber(item?.currentPrice)}`}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <small className="text-[10px]">% Change</small>
                                                            <div className={`bg-[${percentageStatColor}] w-fit px-2 rounded-md`}>
                                                                <p>{`${item?.percentageChange?.toFixed(2)}%`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={() => addItemToFoodItem(
                                                item.marketName,
                                                item.state,
                                                item.commodityName,
                                                item.marketId,
                                                item.currentPrice
                                            )
                                            }
                                                className="lg:flex flex-row bg-green-700 rounded-md py-2 px-4 cursor-pointer h-fit mt-3 hidden">
                                                <p className="text-white text-sm mr-1">Add to my items</p>
                                                <PlusOutlined className="text-xl mt-0 text-white" />
                                            </div>
                                        </div>
                                        <div onClick={() => addItemToFoodItem(
                                                item.marketName,
                                                item.state,
                                                item.commodityName,
                                                item.marketId,
                                                item.currentPrice
                                            )
                                            }
                                                className="flex flex-row self-end bg-green-700 rounded-md py-2 px-4 cursor-pointer h-fit mt-3 lg:hidden w-fit">
                                                <p className="text-white text-sm mr-1">Add to my items</p>
                                                <PlusOutlined className="text-xl mt-0 text-white" />
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    );
}
