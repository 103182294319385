import React from "react";
import "firebase/auth";
import "firebase/firebase-firestore";
import { TiThMenu } from "react-icons/ti";
import { MdCancel } from "react-icons/md";
import ArketLogo from '../../assets/arketColoredLogo.png';
import SideMenu from "./SideMenu";
import { Link } from "react-router-dom";

export default function SideMenuBar() {
    const [collapseShow, setCollapseShow] = React.useState("hidden");

    return (
        <>
            {/* Full sidebar on large screens (lg and above), collapsible on smaller screens */}
            <nav className="lg:left-0 lg:block lg:fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto lg:flex-row lg:flex-nowrap lg:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative lg:w-64 z-10 lg:py-4 py-1">
                <div className="lg:flex-col lg:items-stretch lg:min-h-full lg:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    {/* Toggler visible only on small and medium screens */}
                    <div className="flex flex-row py-2">
                        <button
                            className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                            type="button"
                            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
                        >
                            <TiThMenu />
                        </button>
                        <Link to='/'>
                            {/* Show logo only on small and medium screens */}
                            <img src={ArketLogo} alt="arket logo" className="ml-24 lg:hidden block" />
                        </Link>
                    </div>
                    <div
                        className={
                            "lg:flex lg:flex-col lg:items-stretch lg:opacity-100 lg:relative lg:mt-4 lg:shadow-none absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded border-b-2 " +
                            collapseShow
                        }
                    >
                        {/* Collapse header for mobile and medium screens */}
                        <div className="lg:min-w-full lg:hidden block pb-4 mb-4">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <img src={ArketLogo} alt="arket logo" className="lg:hidden block" />
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() => setCollapseShow("hidden")}
                                    >
                                        <MdCancel />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* SideMenu component */}
                        <SideMenu hideMenu={() => setCollapseShow("hidden")} />
                    </div>
                </div>
            </nav>
        </>
    );
}
