import { useState, useEffect } from "react";
import api from "../libs/api";
import { captureException } from "@sentry/react";

export default function LandingPagePriceImage({ commodityName }: { commodityName: string }) {
    const [image, setImage] = useState<string>('');
    useEffect(() => {
        async function getImage() {
            try {
                const apiResponse = await api.get(`/commodity/image/${commodityName}`);
                if (apiResponse.status === 200) {
                    if(apiResponse.data) {
                        setImage(apiResponse.data);
                    } else {
                        setImage('https://res.cloudinary.com/dmdsl1dei/image/upload/v1724594054/arketSinglelogo_yx8law.png')
                    }
                } else {
                    setImage('https://res.cloudinary.com/dmdsl1dei/image/upload/v1724594054/arketSinglelogo_yx8law.png')
                }
            } catch (error) {
                captureException(error);
            }
        }
        void getImage()
    }, [commodityName])
    if (!image) return null;
    return (
        <img
            className="w-full h-56 object-cover"
            src={image}
            alt={image}
        />
    )
}