import React from "react";
import "firebase/auth";
import "firebase/firebase-firestore";
import { TiThMenu } from "react-icons/ti";
import { MdCancel } from "react-icons/md";
import ArketLogo from '../../assets/arketColoredLogo.png';
import { useLocation } from "react-router-dom";

export default function MobileHeader() {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    return (
        <>
            {/* Mobile header visible on screens smaller than 'lg' */}
            <nav className="lg:hidden block fixed top-0 bottom-0 shadow-xl bg-transparent flex flex-wrap items-center justify-between relative w-full z-10 py-1">
                <div className="flex flex-wrap items-center justify-between w-full mx-auto">
                    {/* Toggler */}
                    <div className="ml-auto py-2">
                        <button
                            className="cursor-pointer text-black dark:text-white px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                            type="button"
                            onClick={() => setIsMenuOpen(true)}
                        >
                            <TiThMenu className="text-white" />
                        </button>
                    </div>
                    <div
                        className={
                            "absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded border-b-2 " +
                            (isMenuOpen ? "bg-white dark:bg-gray-800 m-2 py-3 px-6" : "hidden")
                        }
                    >
                        {/* Collapse header */}
                        <div className="block pb-4 mb-4">
                            <div className="flex flex-wrap">
                                <div className="w-6/12 lg:block">
                                    <img src={ArketLogo} alt="arket logo" className="block" />
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black dark:text-white opacity-50 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        <MdCancel className="text-black dark:text-white" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Navigation */}
                        <div>
                            {
                                location.pathname === '/markets' ? (
                                    <a href="/">
                                        <p className="text-[#A5AFA0] dark:text-[#FAFAFA] text-sm mt-3">Home</p>
                                    </a>
                                ) : (
                                    <a href="/markets">
                                        <p className="text-[#A5AFA0] dark:text-[#FAFAFA] text-sm mt-3">Market locations</p>
                                    </a>
                                )
                            }
                            <a href="/signup">
                                <p className="text-[#A5AFA0] dark:text-[#FAFAFA] text-sm mt-3">My account</p>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}
