interface Props {
    onChange: (text: string) => void;
    placeholder: string;
    extraStyle?: string;
    label: string;
    value?: any;
    defaultValue?: any;
    name: string;
    type: 'text' | 'password' | 'number';
}

export default function Input({ onChange, placeholder, extraStyle, label, name, type, value, defaultValue }: Props) {
    return (
        <div>
            {label && <label className="block text-[#8A92A6] text-sm font-bold mb-2" htmlFor={name}>{label}</label>}
            <input
                id={name}
                defaultValue={defaultValue}
                value={value}
                type={type}
                placeholder={placeholder}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline ${extraStyle}`}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}