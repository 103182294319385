import { useEffect, useRef, useState } from 'react';
import { MyFoodItems } from '../interfaces/my-food-items.interface';
import api from '../libs/api';
import firebase from "firebase/app";
import "firebase/auth";
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { FaTrashAlt } from 'react-icons/fa';
import { captureException } from '@sentry/react';
import MyFoodItemProductCardPrice from './MyFoodItemProductCardPrice';
import MyFoodItemProductImage from './MyFoodItemProductImage';

export default function MyFoodItemsTable() {
  const toast = useRef<Toast>(null);
  const [myFoodItems, setMyFoodItems] = useState<MyFoodItems[]>()
  useEffect(() => {
    async function getMyFoodItems() {
      try {
        const apiResponse = await api.get(`/myfooditems/${firebase.auth().currentUser?.uid}`);
        if (apiResponse.status === 200) {
          const foodItems = apiResponse.data as MyFoodItems[];
          setMyFoodItems(foodItems.reverse());
        }
      } catch (error) {
        captureException(error)
      }
    }
    getMyFoodItems();
  }, [])

  const deleteConfirm = (id: string) => {
    confirmDialog({
      message: 'Do you want to remove this item?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'bg-red-500 p-1 px-2 text-white',
      rejectClassName: 'bg-[#C7EBD7] p-1 px-2 text-black mr-3',
      accept: async () => {
        try {
          toast?.current?.show({ severity: 'info', summary: 'Please wait...', detail: 'Deleting...', life: 2000 });
          const apiResponse = await api.delete(`/myfooditems/${id}`);
          if ([200, 201].includes(apiResponse.status)) {
            window.location.reload();
          }
        } catch (error) {
          captureException(error)
        }
      },
    });
  };

  const ProductCard = (foodItem: MyFoodItems) => {
    return (
      <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
        <MyFoodItemProductImage commodityName={foodItem.commodityName} />
        <div className="px-6 py-4 pb-0">
          <div className="font-medium text-gray-600 text-sm mb-1">
            {foodItem.marketName} • {foodItem.state}
          </div>
          <div className="font-semibold text-lg text-gray-800">
            {foodItem.commodityName}
          </div>
        </div>
        <div className="flex flex-row justify-between px-6 py-4 pt-0">
          <MyFoodItemProductCardPrice marketName={foodItem.marketName} commodityName={foodItem.commodityName} />
          <button className="text-red-600 h-fit hover:text-red-800">
            <FaTrashAlt size={20} onClick={() => deleteConfirm(foodItem._id)} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className='grid lg:grid-cols-4 flex-col lg:gap-5 gap-2'>
        {
          myFoodItems?.map((foodItem) => <ProductCard key={foodItem._id} {...foodItem} />)
        }
      </div>
    </div>
  );
}
