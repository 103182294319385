import { TabView, TabPanel, TabPanelHeaderTemplateOptions } from 'primereact/tabview';
import CreateAlert from '../features/CreateAlert';
import UserAlerts from '../features/UserAlerts';
import { useState, useEffect } from 'react';
import UpdateAlert from '../features/UpdateAlert';
import { analytics } from '../firebase-config';


export default function FoodItemAlerts() {
    analytics.logEvent('page_view', {
        page_title: 'food item alerts'
    });
    const [activeIndex, setActiveIndex] = useState(0);
    const [alertId, setAlertId] = useState<string | undefined>();
    const headerTemplate = (options: TabPanelHeaderTemplateOptions, label: string) => {
        return (
            <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <span className="font-bold white-space-nowrap">{label}</span>
            </div>
        );
    };

    useEffect(() => {
        async function getTabView() {
            const urlParams = new URLSearchParams(window.location.search);
            const tab = urlParams.get('tab');
            tab && setActiveIndex(parseInt(tab))
        }
        getTabView();
    }, [])

    const setAlertIdFunction = (id: string) => {
        setAlertId(id);
        setActiveIndex(2);
    }

    const updateCleanup = () => {
        setAlertId(undefined);
        setActiveIndex(0);
    }
    return (
        <div className="h-screen lg:px-10 px-1 bg-white">
            <div className="mt-20 lg:mb-10 mb-5 ml-3">
                <p className="text-[#121737] text-2xl font-bold font-['Work Sans'] leading-normal">Price Alerts</p>
            </div>
            <div className="card">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="My Alerts" headerTemplate={(options) => headerTemplate(options, 'My Alerts')}>
                        <UserAlerts setCurrentEditAlert={setAlertIdFunction} />
                    </TabPanel>
                    <TabPanel header="Add Alert" headerTemplate={(options) => headerTemplate(options, 'Add Alert')}>
                        <CreateAlert />
                    </TabPanel>
                    {
                        alertId && (
                            <TabPanel header="Manage Alert" headerTemplate={(options) => headerTemplate(options, 'Manage Alert')}>
                                <UpdateAlert id={alertId} updateCleanup={updateCleanup} />
                            </TabPanel>
                        )
                    }
                </TabView>
            </div>
        </div>
    )
}