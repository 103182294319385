import ArketLogo from '../../assets/arketColoredLogo.png';
import Overview from '../../assets/overview.png';
import MyFoodItems from '../../assets/myFoodItems.png';
import { Link, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import { useNavigate } from 'react-router-dom';
import "firebase/auth";

interface SideMenuListItem {
    image: string;
    label: string;
    link: string;
}

const sideMenuItems: SideMenuListItem[] = [
    {
        label: 'Overview',
        image: Overview,
        link: 'home'
    },
    {
        label: 'View Current Prices',
        image: Overview,
        link: 'view-current-prices'
    },
    {
        label: 'My Food Items',
        image: MyFoodItems,
        link: 'my-food-items'
    },
    // {
    //     label: 'Infographic',
    //     image: Overview,
    //     link: 'infographic'
    // },
    {
        label: 'Comparison',
        image: Overview,
        link: 'compare'
    },
    {
        label: 'Food Item Alerts',
        image: Overview,
        link: 'alerts'
    },
]

export default function SideMenu({ hideMenu }: { hideMenu: () => void; }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isSelected = (linkText: string) => {
        const path = `${pathname}`.replace("/", "");
        return `${linkText}`.toLowerCase() === path
            ? "bg-[#F7F8FA] border-r-2 border-green-600"
            : "";
    };
    const logOutUser = async () => {
        await firebase.auth().signOut();
        navigate('/login');
    }
    const bottomSideBars = [
        {
            label: 'My Account',
            onPress: () => navigate('/account')
        },
        {
            label: 'Feedback',
            onPress: () => navigate('/feedback')
        },
        {
            label: 'Logout',
            onPress: logOutUser
        }
    ]
    const SideMenuListItem = ({ image, label, link }: SideMenuListItem) => {
        return (
            <div onClick={() => {
                navigate(`/${link.toLowerCase().replace(' ', '_')}`)
                hideMenu()
            }}>
                <div>
                    <div className={`flex flex-row cursor-pointer h-[52px] py-[16px] w-full ${isSelected(link)}`}>
                        <img src={image} className='w-[20px] h-[20px] ml-4' alt="Side Panel" />
                        <p className='ml-[16px] text-[#027432]'>{label}</p>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <Link to='/'>
                <img src={ArketLogo} alt="Side Panel" className='lg:block hidden lg:ml-5' />
            </Link>
            <div className='mt-[3.7rem]'>
                {
                    sideMenuItems.map(({ image, label, link }) => <SideMenuListItem key={label} image={image} label={label} link={link} />)
                }
            </div>
            <div className='lg:mt-[50px] mt-10 flex flex-col lg:ml-5'>
                {
                    bottomSideBars.map(({ label, onPress }) => (
                        <p key={label} onClick={() => {
                            onPress()
                            hideMenu()
                        }} className='my-2 text-[#A5AFA0] text cursor-pointer'>{label}</p>
                    ))
                }
            </div>
        </div>
    )
}