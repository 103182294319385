import { useRef, useState, useEffect } from "react"
import Input from "../components/ui/Input";
// import { Checkbox } from 'antd';
import { Radio } from 'antd';
import api from "../libs/api";
import { Toast } from "primereact/toast";
import firebase from "firebase/app";
// import { AutoComplete } from 'antd';
import "firebase/auth";
import { Alert } from "../interfaces/alert.interface";
// import { Commodity } from "../interfaces/commodity.interface";
import { Dropdown } from "primereact/dropdown";
import { Market } from "../interfaces/market.interface";
import { Price } from "../interfaces/price.interface";
// import { formatNumber } from "../functions/formatNumber";
import { calculateNewValueAfterPercentDecrease, calculateNewValueAfterPercentIncrease } from "./CreateAlert";
import { captureException } from "@sentry/react";

interface Props {
    id?: string;
    updateCleanup: () => void;
}

export default function UpdateAlert({ id, updateCleanup }: Props) {
    const toast = useRef<Toast>(null);
    const [itemName, setItemName] = useState<{ item: string; category: string }>();
    const [currentAlert, setCurrentAlert] = useState<Alert>();
    const [priceUp, setPriceUp] = useState<number>(0);
    const [priceDown, setPriceDown] = useState<number>(0);
    const [price, setPrice] = useState<Price>();
    const [frequency, setFrequency] = useState('');
    const [isEmail, setIsEmail] = useState(false);
    const [isSMS, setIsSMS] = useState(false);
    const [isInApp, setIsInApp] = useState(false);
    // const [marketLocation, setMarketLocation] = useState<string>('');
    const [marketLocations, setMarketLocations] = useState<Market[]>();
    const [selectedMarket, setSelectedMarket] = useState<Market>();
    const [itemSuggestions, setItemSuggestions] = useState<{ item: string; category: string }[]>([]);

    useEffect(() => {
        async function getAlert() {
            try {
                const alertResponse = await api.get(`/alert/${id}`);
                if (alertResponse.status === 200) {
                    const alertData = alertResponse.data as Alert;
                    setCurrentAlert(alertData);
                    const allItemsResponse = await api.get('/commodity/allItems');
                    if (allItemsResponse.status === 200) {
                        const allItems = allItemsResponse.data as { item: string; category: string }[]
                        setItemSuggestions(allItems.map((item) => {
                            return {
                                item: item.item,
                                category: item.category
                            }
                        }));
                        const item = allItems.find((item) => item.item === alertData.itemName);
                        item && setItemName(item);
                    }
                    setPriceUp(Number(alertData.priceUp));
                    setPriceDown(Number(alertData.priceDown));
                    setFrequency(alertData.frequency);
                    /* const alertData = apiResponse.data as Alert
                    setItemName(alertData.itemName);
                    setPriceUp(alertData.priceUp);
                    setPriceDown(alertData.priceDown);
                    setFrequency(alertData.frequency);
                    setIsEmail(alertData.isEmail);
                    setIsSMS(alertData.isSMS);
                    setIsInApp(alertData.isInApp);
                    setMarketLocation(alertData.marketLocation);  */
                }
            } catch (error) {
                captureException(error)
            }
        }
        getAlert();
    }, [id])

    useEffect(() => {
        async function getmarketLocations() {
            try {
                if (!itemName) return;
                setMarketLocations([])
                // setPrice(undefined);
                // setPriceDown(0)
                // setPriceUp(0)
                const apiResponse = await api.get(`commodity/category-items/market/${itemName.item}`);
                if (apiResponse.status === 200) {
                    const markets = apiResponse.data as Market[];
                    setMarketLocations(markets)
                    const currentMarket = markets.find((market) => market._id === currentAlert?.marketLocation);
                    setSelectedMarket(currentMarket)
                }
            } catch (error) {
                captureException(error)
            }
        }
        void getmarketLocations()
    }, [itemName])

    useEffect(() => {
        async function getItemPrice() {
            try {
                if (!itemName || !selectedMarket) return;
                const apiResponse = await api.get(`/price/all/byName/${itemName.item}/${selectedMarket._id}`);
                if (apiResponse.status === 200) {
                    const data = apiResponse.data as Price;
                    setPrice(data);
                }
            } catch (error) {
                captureException(error)
            }
        }
        void getItemPrice();
    }, [itemName, selectedMarket])

    useEffect(() => {
        return (() => {
            setItemName(undefined)
            setPriceDown(0)
            setPriceUp(0)
            setFrequency('')
            setIsEmail(false)
            setIsSMS(false)
            setIsInApp(false)
            setMarketLocations(undefined)
        })
    }, [])

    const update = async () => {
        try {
            const userId = firebase.auth().currentUser?.uid;
            const apiResponse = await api.post('/alert/update', {
                itemName: itemName?.item,
                priceUp,
                priceDown,
                priceDownAmount: calculateNewValueAfterPercentDecrease(price?.averagePrice, priceDown),
                priceUpAmount: calculateNewValueAfterPercentIncrease(price?.averagePrice, priceUp),
                frequency, 
                isEmail,
                isSMS,
                isInApp,
                userId,
                marketLocation: selectedMarket?._id,
                marketName: selectedMarket?.name,
                alertId: id
            });
            if ([200, 201].includes(apiResponse.status)) {
                setItemName(undefined)
                setPriceDown(0)
                setPriceUp(0)
                setFrequency('')
                setIsEmail(false)
                setIsSMS(false)
                setIsInApp(false)
                // setMarketLocation('')
                toast.current?.show({ severity: "success", summary: '', detail: 'Alert updated successfully.' });
                updateCleanup();
            }
        } catch (error) {
            captureException(error)
        }
    }

    return (
        <div className="h-screen">
            <Toast ref={toast} />
            <p className="font-semibold">Update Price Alert</p>
            <hr className="mt-[16px] mb-[30px]" />
            <div>
                <label className="block text-[#8A92A6] text-sm font-bold mb-2">Item Name</label>
                <Dropdown value={itemName} optionLabel="item" onChange={(e) => setItemName(e.value)} options={itemSuggestions}
                    placeholder="Select food item" className="md:w-14rem border border-1 w-full lg:max-w-[90vw] h-fit" />
            </div>
            <div>
            <p className="mt-5 mb-2">Select market</p>
                <div>
                    <Dropdown value={selectedMarket} optionLabel="name" onChange={(e) => setSelectedMarket(e.value)} options={marketLocations}
                        placeholder="Select market" className="md:w-14rem border border-1 w-full lg:max-w-[90vw] h-fit" />
                </div>
            </div>
            <div className="flex lg:flex-row flex-col gap-3 justify-between mt-5">
                <div>
                    <p className="font-semibold">Price Up</p>
                    <Input defaultValue={priceUp} value={`${priceUp}`} placeholder="Ex: 2,000" type="number" label="Enter amount" onChange={(e) => setPriceUp(Number(e))} name="price up" extraStyle="h-[54px] lg:w-[35vw]" />
                    {/* {
                        price && priceUp > 0 && (
                            <div className="p-1 bg-green-300 w-fit rounded-md mt-2">
                                <p>N{formatNumber(calculateNewValueAfterPercentIncrease(price?.averagePrice, priceUp))}</p>
                            </div>
                        )
                    } */}
                </div>
                {/* <div>
                    <p className="font-semibold">Price Down</p>
                    <Input defaultValue={priceDown} value={`${priceDown}`} placeholder="Ex: 20%" type="number" label="Percentage decrease" onChange={(e) => setPriceDown(Number(e))} name="price down" extraStyle="h-[54px] lg:w-[35vw]" />
                    {
                        price && priceDown > 0 && (
                            <div className="p-1 bg-red-300 w-fit rounded-md mt-2">
                                <p>N{formatNumber(calculateNewValueAfterPercentDecrease(price?.averagePrice, priceDown))}</p>
                            </div>
                        )
                    }
                </div> */}
            </div>
            <div className="card flex flex-col mt-4">
                <p className="font-semibold mb-4">Frequency</p>
                <div className="grid grid-cols-3 gap-3 w-full">
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Radio name="pizza" value="Once" onChange={(e) => setFrequency(e.target.value)} checked={frequency === 'Once'} />
                        <label htmlFor="ingredient1" className="lg:ml-2">Once</label>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Radio value="Daily" onChange={(e) => setFrequency(e.target.value)} checked={frequency === 'Daily'} />
                        <label htmlFor="ingredient2" className="lg:ml-2">Daily</label>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Radio value="Weekly" onChange={(e) => setFrequency(e.target.value)} checked={frequency === 'Weekly'} />
                        <label htmlFor="ingredient3" className="lg:ml-2">Weekly</label>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Radio value="Monthly" onChange={(e) => setFrequency(e.target.value)} checked={frequency === 'Monthly'} />
                        <label htmlFor="ingredient3" className="lg:ml-2">Monthly</label>
                    </div>
                </div>
            </div>
            {/* <div className="card flex flex-col mt-10">
                <p className="font-semibold mb-4">How should we notify you</p>
                <div className="grid grid-cols-3 gap-3 w-full">
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Checkbox value={isEmail} onChange={(e) => setIsEmail(e.target.checked)}>Email</Checkbox>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Checkbox value={isSMS} onChange={(e) => setIsSMS(e.target.checked)}>SMS</Checkbox>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Checkbox value={isInApp} onChange={(e) => setIsInApp(e.target.checked)}>In-app</Checkbox>
                    </div>
                </div>
            </div> */}
            <button onClick={update} className="bg-[#69A020] w-full text-white py-3 px-4 rounded focus:outline-none focus:shadow-outline font-bold mt-10" type="button">
                Update
            </button>
        </div>
    )
}