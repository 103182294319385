import { useState, useEffect } from "react";
import api from "../libs/api";
import { Price } from "../interfaces/price.interface";
import { formatNumber } from "../functions/formatNumber";
import { FaCalendar } from "react-icons/fa";
import { TbRulerMeasure } from "react-icons/tb";

export default function MyFoodItemProductCardPrice({ marketName, commodityName }: { marketName: string; commodityName: string }) {
    const [price, setCurrentPrice] = useState<Price>();
    useEffect(() => {
        async function getCurrentPrice() {
            const apiResponse = await api.get(`/price/all/byName/${commodityName}/${marketName}`);
            if (apiResponse.status === 200) {
                setCurrentPrice(apiResponse.data);
            }
        }
        void getCurrentPrice()
    }, [commodityName, marketName])
    if (!price) return null;
    return (
        <div className="flex flex-col mt-2">
            <small>Latest price:</small>
            <span className="text-green-600 font-bold text-xl">{formatNumber(price?.averagePrice)} {price.currency}</span>
            <div className="flex flex-row gap-1 mt-1">
                <TbRulerMeasure />
                <small>{price.unit}</small>
            </div>
            <div className="flex flex-row gap-1 mt-1">
                <FaCalendar />
                <small>{new Date(price.date).toDateString()}</small>
            </div>
        </div>
    )
}