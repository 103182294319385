import { useState, useEffect } from "react";
import firebase from "firebase/app";
import { useNavigate } from "react-router-dom";
import "firebase/auth";
import "firebase/firebase-firestore";
import { confirmDialog } from 'primereact/confirmdialog';
import { captureException } from "@sentry/react";
import api from "../libs/api";
import { analytics } from "../firebase-config";

export interface UserProfile {
    email: string;
    firstName: string;
    lastName: string;
    hasSubmittedFeedback: boolean;
}

export default function MyAccount() {
    analytics.logEvent('page_view', {
        page_title: 'my account'
    });
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState<UserProfile>();

    useEffect(() => {
        async function getUserData() {
            const userData = await firebase.firestore().collection('users').doc(firebase.auth().currentUser?.uid).get();
            if (userData.exists) {
                const data = userData.data() as UserProfile;
                setUserProfile(data);
            }
        }
        void getUserData();
    }, [])

    const deleteConfirm = () => {
        confirmDialog({
            message: 'This will delete your account and all related data permanently.',
            header: 'Delete your account?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'bg-red-500 p-1 px-2 text-white',
            rejectClassName: 'bg-[#C7EBD7] p-1 px-2 text-black mr-3',
            accept: async () => {
                try {
                    const apiResponse = await api.delete(`/account/${firebase.auth().currentUser?.uid}`);
                    if (apiResponse.status === 200) {
                        localStorage.clear();
                        firebase.auth().signOut().then(() => {
                            navigate('/');
                        })
                    }
                } catch (error) {
                    captureException(error)
                }
            },
        });
    };

    return (
        <div className="h-screen px-10 bg-[#fff]">
            <div className="mt-16">
                <p className="text-[#121737] text-2xl font-bold font-['Work Sans'] leading-normal">My Account</p>
            </div>
            <hr className="mt-5 mb-10" />
            <div className="flex flex-col lg:flex-row gap-5 mb-5">
                <div className="lg:max-w-sm lg:w-3/4 bg-white shadow-md rounded-lg p-6">
                    <div className="border-b pb-4 mb-4">
                        <h2 className="text-xl font-semibold">User Info</h2>
                    </div>
                    <div className="mb-4">
                        <p className="text-gray-600 font-medium">Name</p>
                        <p className="text-lg font-semibold">{userProfile?.firstName} {userProfile?.lastName}</p>
                    </div>
                    <div className="mb-6">
                        <p className="text-gray-600 font-medium">Email</p>
                        <p className="text-lg font-semibold">{firebase.auth().currentUser?.email}</p>
                    </div>
                    <button onClick={() => navigate('/forgot-password')} className="bg-green-100 text-green-700 py-2 px-4 rounded-md hover:bg-green-200 transition-colors">
                        Update Password
                    </button>
                </div>
                <div className="lg:max-w-sm lg:w-2/4 bg-white shadow-md rounded-lg p-6 h-fit">
                    <div className="border-b pb-4 mb-4">
                        <h2 className="text-xl font-semibold">Contact support</h2>
                    </div>
                    <div className="mb-6">
                        <p className="text-gray-600 font-medium">Email</p>
                        <p className="text-lg font-semibold">info@arket.com</p>
                    </div>
                </div>
            </div>
            <button onClick={deleteConfirm} className="bg-red-500 text-white font-semibold mb-10">Delete account</button>
        </div>
    )
}