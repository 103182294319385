import React, { useEffect, useState, useRef } from "react";
import { Navigate, Link } from "react-router-dom";
import { useAuthListener } from "../hooks/authListenerHook";
import SideMenuBar from "../components/feature/SideMenuBar";
import AuthHeader from "../components/feature/AuthHeader";
import { ComponentType } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import { UserProfile } from "../routes/MyAccount";

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component }) => {
  const { loggedIn, checkingStatus } = useAuthListener();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (loggedIn) {
      const checkFeedbackStatus = async () => {
        const user = firebase.auth().currentUser;
        if (user) {
          const userData = await firebase.firestore().collection('users').doc(user.uid).get();
          if (userData.exists) {
              const data = userData.data() as UserProfile;
              if (!data.hasSubmittedFeedback) {
                setShowFeedbackModal(true);
              }
          }
        }
      };

      // Run immediately on component mount
      // checkFeedbackStatus();

      // Set up interval to run every 5 minutes
      intervalRef.current = setInterval(checkFeedbackStatus, 500000); // 300,000 ms = 5 minutes

      // Clean up interval on component unmount
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [loggedIn]);

  if (!loggedIn) {
    localStorage.setItem('pendingNavigation', window.location.pathname);
  }

  return (
    <>
      <SideMenuBar />
      {
        checkingStatus ? (
          <div style={{ marginTop: window.innerHeight / 3 }}>
            {/* Loading Spinner or Placeholder */}
          </div>
        ) : loggedIn ? (
          <div className="flex flex-col lg:ml-[270px]">
            <div className="lg:block hidden">
              <AuthHeader />
            </div>
            <Component />

            {/* Feedback Modal */}
            <Dialog
              header="We Value Your Feedback!"
              visible={showFeedbackModal}
              // style={{ width: '50vw' }}
              className="lg-w-1/2 w-3/4"
              closable={false}
              onHide={() => setShowFeedbackModal(false)}
            >
              <p>
                We noticed that you haven't submitted feedback yet. Your thoughts help us improve!
              </p>
              <div className="flex lg:flex-row flex-col gap-5 justify-end mt-4">
                <Button
                  label="Maybe Later"
                  className="p-button-text mr-2 dark:text-white w-fit"
                  onClick={() => setShowFeedbackModal(false)}
                />
                <Link to="/feedback">
                  <Button
                    label="Submit Feedback"
                    className="p-button-primary dark:text-white"
                    onClick={() => setShowFeedbackModal(false)}
                  />
                </Link>
              </div>
            </Dialog>
          </div>
        ) : (
          <Navigate to="/login" />
        )
      }
    </>
  );
};

export default ProtectedRoute;
