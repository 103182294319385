import React, { ErrorInfo, ReactNode } from 'react';
import ErrorPage from './ErrorPage';
import { captureException } from '@sentry/react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorInfo: ErrorInfo | null;
}

declare global {
  interface Window {
    __showGlobalErrorPage?: () => void;
  }
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false, errorInfo: null };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ errorInfo });
    this.sendErrorReport(error, errorInfo);
  }

  componentDidMount() {
    window.__showGlobalErrorPage = () => {
      this.setState({ hasError: true });
    };
  }

  componentWillUnmount() {
    window.__showGlobalErrorPage = undefined;
  }

  sendErrorReport = (error: Error, errorInfo: ErrorInfo): void => {
    captureException({error, errorInfo})
    /*fetch('/api/error-report', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        error: error.toString(),
        errorInfo: errorInfo.componentStack,
      }),
    }).catch((err) => console.error('Error reporting failed:', err)); */
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
