import { useState, useEffect } from "react";
import api from "../libs/api";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Market } from "../interfaces/market.interface";
import MarketLocationPrice from "../features/MarketLocationPrice";
import { Commodity } from "../interfaces/commodity.interface";
import { captureException } from "@sentry/react";
import { analytics } from "../firebase-config";

export default function Compare() {
    analytics.logEvent('page_view', {
        page_title: 'compare'
    });
    const [marketLocations, setMarketLocations] = useState<Market[]>();
    const [options, setOptions] = useState<{ id: string; item: string; category: string; }[]>([]);
    const [selectedItem, setSelectedItem] = useState<Commodity>();
    const [selectedMarkets, setSelectedMarkets] = useState<Market[]>();
    const [showResult, setShowResult] = useState(false);

    useEffect(() => {
        async function getAllItems() {
            try {
                const apiResponse = await api.get('/commodity/allItems');
                if (apiResponse.status === 200) {
                    const allItems = apiResponse.data as { item: string; category: string }[];
                    const sortedItems = [...allItems].sort((a, b) => a.item.localeCompare(b.item));
                    setOptions(sortedItems.map((item) => {
                        return {
                            id: item.category,
                            item: item.item,
                            category: item.category
                        };
                    }));
                }
            } catch (error) {
                captureException(error);
            }
        }
        getAllItems();
    }, []);

    useEffect(() => {
        async function getMarketLocations() {
            try {
                setShowResult(false);
                if (!selectedItem) return;
                setSelectedMarkets([]);
                setMarketLocations([]);
                const apiResponse = await api.get(`commodity/category-items/market/${selectedItem.item}`);
                if (apiResponse.status === 200) {
                    const markets = apiResponse.data as Market[];
                    const sortedItems = [...markets].sort((a, b) => a.name.localeCompare(b.name));
                    setMarketLocations(sortedItems);
                }
            } catch (error) {
                captureException(error);
            }
        }
        getMarketLocations();
    }, [selectedItem]);

    const doCompare = async () => {
        setShowResult(true);
    };

    return (
        <div className="min-h-screen p-4 bg-white dark:bg-gray-900">
            <div className="">
                <p className="text-[#121737] dark:text-white text-2xl font-bold font-['Work Sans'] leading-normal">
                    Compare Prices
                </p>
                <p className="mt-5 font-['Manrope'] text-[#060606] dark:text-gray-300">
                    You can compare the price of a single food item between two different markets. This can help you understand the pricing dynamics and make better decisions about where to buy your product.
                </p>
            </div>
            <hr className="my-5 border-gray-200 dark:border-gray-700" />
            <div>
                <div className="flex flex-col">
                    <p className="text-[#060606] dark:text-gray-100">Select food item</p>
                    <Dropdown
                        value={selectedItem}
                        optionLabel="item"
                        onChange={(e) => setSelectedItem(e.value)}
                        options={options}
                        placeholder="Select food item"
                        filter
                        className="w-full max-w-full border border-gray-300 dark:border-gray-700 mt-2 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                        // autoWidth={false}
                    />
                </div>
                {selectedItem && (
                    <div className="flex flex-col mt-5">
                        <p className="mb-2 text-[#060606] dark:text-gray-100">
                            Markets selling: <b>{selectedItem.item}</b>
                        </p>
                        <MultiSelect
                            value={selectedMarkets}
                            onChange={(e) => setSelectedMarkets(e.value)}
                            options={marketLocations}
                            optionLabel="name"
                            display="chip"
                            placeholder="Select markets"
                            maxSelectedLabels={2}
                            className="w-full max-w-full border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                            style={{ maxWidth: '100%' }}
                        />
                        <small className="text-gray-600 dark:text-gray-400">Select multiple markets when available</small>
                        <button
                            onClick={doCompare}
                            className="bg-[#027432] w-fit text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline font-bold mt-5"
                            type="button"
                        >
                            Check prices
                        </button>
                    </div>
                )}
                {selectedItem && selectedMarkets && selectedMarkets.length > 0 && showResult && (
                    <div className="mt-5 flex gap-3 flex-col lg:flex-row">
                        {selectedMarkets.map((mk) => (
                            <MarketLocationPrice
                                key={mk._id}
                                item={selectedItem.item}
                                marketLocation={mk._id}
                                state={mk.state}
                                marketName={mk.name}
                                marketId={mk._id}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
