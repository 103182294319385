import { useState, useEffect } from "react";
import api from "../libs/api";
import { captureException } from "@sentry/react";

export default function UserAlertImage({ commodityId }: { commodityId: string }) {
    const [image, setImage] = useState<string>();
    useEffect(() => {
        async function getImage() {
            try {
                const apiResponse = await api.get(`/commodity/image/${commodityId}`);
                if (apiResponse.status === 200) {
                    setImage(apiResponse.data);
                }
            } catch (error) {
                captureException(error)
            }
        }
        void getImage()
    }, [commodityId])
    if (!image) return null;
    return (
        <img
            className="w-[66px] h-[66px] object-cover"
            src={image}
            alt={image}
        />
    )
}