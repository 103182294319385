import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCVfZK89WOtE5jKpKx-wtTRBuHFeho6-_c",
  authDomain: "arket-data-admin.firebaseapp.com",
  projectId: "arket-data-admin",
  storageBucket: "arket-data-admin.appspot.com",
  databaseURL: "https://arket-data-admin-default-rtdb.firebaseio.com",
  messagingSenderId: "27598283419",
  appId: "1:27598283419:web:97c860373473e920c8dc3e",
  measurementId: "G-21FFZ3228N"
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const analytics = firebase.analytics();
