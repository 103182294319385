import { useRef, useState, useEffect } from "react"
import Input from "../components/ui/Input";
// import { Checkbox } from 'antd';
import { Radio } from 'antd';
import api from "../libs/api";
import { Toast } from "primereact/toast";
import firebase from "firebase/app";
// import { AutoComplete } from 'antd';
import "firebase/auth";
import { Dropdown } from "primereact/dropdown";
import { Market } from "../interfaces/market.interface";
// import { Commodity } from "../interfaces/commodity.interface";
import { Price } from "../interfaces/price.interface";
import { formatNumber } from "../functions/formatNumber";
import AddItemModal from "../components/feature/AddItemAlert";
import { captureException } from "@sentry/react";

export function calculateNewValueAfterPercentIncrease(originalValue?: number, percentIncrease?: number): number {
    if(!originalValue || !percentIncrease) return 0;
    const increaseAmount = (originalValue * percentIncrease) / 100;
    const newValue = originalValue + increaseAmount;
    return newValue;
}

export function calculateNewValueAfterPercentDecrease(originalValue?: number, percentDecrease?: number): number {
    if(!originalValue || !percentDecrease) return 0;
    const decreaseAmount = (originalValue * percentDecrease) / 100;
    const newValue = originalValue - decreaseAmount;
    return newValue;
}

export default function CreateAlert() {
    const toast = useRef<Toast>(null);
    const [marketLocations, setMarketLocations] = useState<Market[]>();
    const [itemName, setItemName] = useState<{ item: string; category: string }>();
    const [price, setPrice] = useState<Price>();
    const [priceUp, setPriceUp] = useState<number>(0);
    const [priceDown, setPriceDown] = useState<number>(0);
    const [frequency, setFrequency] = useState('');
    const [isEmail, setIsEmail] = useState(false);
    const [isSMS, setIsSMS] = useState(false);
    const [isInApp, setIsInApp] = useState(false);
    const [selectedMarket, setSelectedMarket] = useState<Market>();
    const [itemSuggestions, setItemSuggestions] = useState<{ item: string; category: string }[]>([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        async function getAllItems() {
            try {
                const apiResponse = await api.get('/commodity/allItems');
                if (apiResponse.status === 200) {
                    const allItems = apiResponse.data as { item: string; category: string }[]
                    const sortedItems = [...allItems].sort((a, b) => a.item.localeCompare(b.item));
                    setItemSuggestions(sortedItems.map((item) => {
                        return {
                            item: item.item,
                            category: item.category
                        }
                    }));
                    const urlParams = new URLSearchParams(window.location.search);
                    const paramsItem = urlParams.get('item');
                    const item = sortedItems.find((item) => item.item === paramsItem);
                    item && setItemName(item);
                }
            } catch (error) {
                captureException(error)
            }
        }
        void getAllItems();
    }, [])

    useEffect(() => {
        return (() => {
            setItemName(undefined)
            setPriceDown(0)
            setPriceUp(0)
            setFrequency('')
            setIsEmail(false)
            setIsSMS(false)
            setIsInApp(false)
        })
    }, [])

    useEffect(() => {
        async function getmarketLocations() {
            try {
                if (!itemName) return;
                setMarketLocations([])
                setPrice(undefined);
                setPriceDown(0)
                setPriceUp(0)
                const apiResponse = await api.get(`commodity/category-items/market/${itemName.item}`);
                if (apiResponse.status === 200) {
                    const markets = apiResponse.data as Market[];
                    const sortedItems = [...markets].sort((a, b) => a.name.localeCompare(b.name));
                    setMarketLocations(sortedItems)
                    const urlParams = new URLSearchParams(window.location.search);
                    const paramsMarket = urlParams.get('market');
                    const currentMarket = sortedItems.find((market) => market.name === paramsMarket);
                    currentMarket && setSelectedMarket(currentMarket)
                }
            } catch (error) {
                captureException(error)
            }
        }
        void getmarketLocations()
    }, [itemName])

    useEffect(() => {
        async function getItemPrice() {
            try {
                if (!itemName || !selectedMarket) return;
                const apiResponse = await api.get(`/price/all/byName/${itemName.item}/${selectedMarket.name}`);
                if (apiResponse.status === 200) {
                    const data = apiResponse.data as Price;
                    setPrice(data);
                }
            } catch (error) {
                captureException(error)
            }
        }
        void getItemPrice();
    }, [itemName, selectedMarket])

    const save = async () => {
        try {
            const userId = firebase.auth().currentUser?.uid;
            const apiResponse = await api.post('/alert/new', {
                itemName: itemName?.item,
                priceUp,
                priceDown,
                priceDownAmount: calculateNewValueAfterPercentDecrease(price?.averagePrice, priceDown),
                //priceUpAmount: calculateNewValueAfterPercentIncrease(price?.averagePrice, priceUp),
                priceUpAmount: priceUp,
                frequency,
                isEmail,
                isSMS,
                isInApp,
                userId,
                email: firebase.auth().currentUser?.email,
                marketLocation: selectedMarket?._id,
                commodity: price?.commodity,
                marketName: selectedMarket?.name,
            });
            if ([200, 201].includes(apiResponse.status)) {
                setItemName(undefined)
                setPriceDown(0)
                setPriceUp(0)
                setFrequency('')
                setIsEmail(false)
                setIsSMS(false)
                setIsInApp(false)
                setShowModal(true)
                // toast.current?.show({ severity: "success", summary: '', detail: 'Alert added successfully.' });
            }
        } catch (error) {
            captureException(error)
        }
    }


    return (
        <div className="min-h-screen">
            <Toast ref={toast} />
            {
                price && (
                    <AddItemModal
                        isOpen={showModal}
                        price={price.averagePrice}
                        onClose={() => setShowModal(false)}
                        itemName={itemName?.item || ''}
                        itemImage=''
                        // priceDropFrom={price?.averagePrice.toString() || '0'}
                        priceDropTo={`N${formatNumber(calculateNewValueAfterPercentDecrease(price?.averagePrice, priceDown))}`}
                        priceRiseTo={`N${formatNumber(calculateNewValueAfterPercentIncrease(price?.averagePrice, priceUp))}`}
                        email={firebase.auth().currentUser?.email || ''}
                    />
                )
            }
            <p className="font-semibold">Create Price Alert</p>
            <hr className="mt-[16px] mb-[30px]" />
            <div>
                <label className="block text-[#8A92A6] text-sm font-bold mb-2">Item Name</label>
                <Dropdown value={itemName} optionLabel="item" filter onChange={(e) => setItemName(e.value)} options={itemSuggestions}
                    placeholder="Select food item" className="md:w-14rem border border-1 w-full lg:max-w-[90vw] h-fit" />
            </div>
            <div className="mb-1">
                <p className="mt-5 mb-2">Select market</p>
                <Dropdown value={selectedMarket} optionLabel="name" onChange={(e) => setSelectedMarket(e.value)} options={marketLocations}
                    placeholder="Select market" className="md:w-14rem border border-1 w-full lg:max-w-[90vw] h-fit" />
            </div>
            <div className="flex flex-col gap-2 mt-5">
                <p className="ml-1"><span className="font-semibold">Price:</span> {price?.averagePrice} {price?.currency}</p>
                <p className="ml-1"><span className="font-semibold">Unit:</span> {price?.unit}</p>
            </div>
            <div className="flex lg:flex-row flex-col gap-3 justify-between mt-5">
                <div>
                    <p className="font-semibold">Notify me when price gets to:</p>
                    <Input value={`${priceUp}`} placeholder="Ex: 2,000" type="number" label="Enter amount" onChange={(e) => setPriceUp(Number(e))} name="price change" extraStyle="h-[54px] lg:w-[35vw] dark:bg-white dark:text-black" />
                    {/* {
                        price && priceUp > 0 && (
                            <div className="p-1 bg-green-300 w-fit rounded-md mt-2">
                                <p>N{formatNumber(calculateNewValueAfterPercentIncrease(price?.averagePrice, priceUp))}</p>
                            </div>
                        )
                    } */}
                </div>
                {/* <div>
                    <p className="font-semibold">Price Down</p>
                    <Input value={`${priceDown}`} placeholder="Ex: 20%" type="number" label="Percentage decrease" onChange={(e) => setPriceDown(Number(e))} name="price down" extraStyle="h-[54px] lg:w-[35vw]" />
                    {
                        price && priceDown > 0 && (
                            <div className="p-1 bg-red-300 w-fit rounded-md mt-2">
                                <p>N{formatNumber(calculateNewValueAfterPercentDecrease(price?.averagePrice, priceDown))}</p>
                            </div>
                        )
                    }
                </div> */}
            </div>
            <div className="card flex flex-col mt-4">
                <p className="font-semibold mb-4">Frequency of reminders</p>
                <div className="grid grid-cols-3 gap-1 lg:gap-5 w-full">
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Radio name="Once" value="Once" onChange={(e) => setFrequency(e.target.value)} checked={frequency === 'Once'} />
                        <label htmlFor="Once" className="lg:ml-2">Once</label>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Radio name="Daily" value="Daily" onChange={(e) => setFrequency(e.target.value)} checked={frequency === 'Daily'} />
                        <label htmlFor="Daily" className="lg:ml-2">Daily</label>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Radio name="Weekly" value="Weekly" onChange={(e) => setFrequency(e.target.value)} checked={frequency === 'Weekly'} />
                        <label htmlFor="Weekly" className="lg:ml-2">Weekly</label>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Radio name="Monthly" value="Monthly" onChange={(e) => setFrequency(e.target.value)} checked={frequency === 'Monthly'} />
                        <label htmlFor="Monthly" className="lg:ml-2">Monthly</label>
                    </div>
                </div>
            </div>
            <div className="card flex flex-col">
                {/* <p className="font-semibold mb-4">How should we notify you</p>
                <div className="grid grid-cols-3 gap-3 w-full">
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Checkbox value={isEmail} onChange={(e) => setIsEmail(e.target.checked)}>Email</Checkbox>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Checkbox value={isSMS} onChange={(e) => setIsSMS(e.target.checked)}>SMS</Checkbox>
                    </div>
                    <div className="flex align-items-center border-[1px] rounded-md p-4">
                        <Checkbox value={isInApp} onChange={(e) => setIsInApp(e.target.checked)}>In-app</Checkbox>
                    </div>
                </div> */}
            </div>
            <button onClick={save} className="bg-[#69A020] w-full text-white py-3 px-4 rounded focus:outline-none focus:shadow-outline font-bold mt-10" type="button">
                Save Alert
            </button>
        </div>
    )
}