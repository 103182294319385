import { useEffect, useState } from 'react';
import api from '../libs/api';
import { Dropdown } from 'primereact/dropdown';
import { captureException } from '@sentry/react';
// import CommodityPricePerformanceChart from '../features/LastSearchMarketInsights';

/* type Item = {
    amount: number;
    name: string;
}; */

/* interface GraphInfo {
    graphData: number[];
    graphLabels: string[];
} */

export default function Infographic() {
    const [selectedItem, setSelectedItem] = useState<string>();
    const [items, setItems] = useState<string[]>([]);
    // const [graphData, setGraphData] = useState<GraphInfo>();

    /* function calculateAverageByName(items: Item[]): Item[] {
        const nameMap: { [key: string]: { total: number; count: number } } = {};

        // Group items by name and calculate total and count
        items.forEach(item => {
            if (!nameMap[item.name]) {
                nameMap[item.name] = { total: 0, count: 0 };
            }
            nameMap[item.name].total += item.amount;
            nameMap[item.name].count += 1;
        });

        // Create result array with average amounts
        const result: Item[] = Object.keys(nameMap).map(name => ({
            name,
            amount: nameMap[name].total / nameMap[name].count,
        }));

        return result;
    } */

    /* function getMonthName(dateString: string): string {
        const date = new Date(dateString);
        const monthIndex = date.getUTCMonth(); // getMonth returns month index from 0 (January) to 11 (December)

        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return monthNames[monthIndex];
    } */

    useEffect(() => {
        async function getAllItems() {
            try {
                const apiResponse = await api.get('/commodity/allItems');
                if (apiResponse.status === 200) {
                    setItems(apiResponse.data);
                }
            } catch (error) {
                captureException(error)
            }
        }
        getAllItems();
    }, [])

    useEffect(() => {
        async function getPriceTrend() {
            const insight = await api.get(`/commodity/insights/${selectedItem}`)
            if (insight.status === 200) {
                /* const parsedData = insight.data.map((insight: any) => {
                    return {
                        name: `${getMonthName(insight.date)}`,
                        amount: insight.averagePrice,
                    }
                });
                const abc = calculateAverageByName(parsedData);
                const parsedAverage = calculateAverageByName(parsedData).map((item) => {
                    return {
                        name: item.name,
                        amount: item.amount.toFixed(0)
                    }
                });
                const p2 = parsedAverage.map((item) => {
                    return {
                        name: item.name,
                        amount: parseInt(item.amount).toFixed(0)
                    }
                });
                setGraphData({
                    graphData: p2.map((item) => Number(item.amount)),
                    graphLabels: p2.map((item) => item.name)
                }) */
            }
        }
        if (selectedItem) {
            getPriceTrend();
        }
    }, [selectedItem]);
    return (
        <div className="h-screen p-10">
            <Dropdown value={selectedItem} onChange={(e) => setSelectedItem(e.value)} options={items}
                placeholder="Select item" className="md:w-14rem border border-1 w-full max-w-[262px]" />
            <div className='mt-12'>
            </div>
        </div>
    )
}