import { useState, useEffect, useRef } from "react";
import api from "../libs/api";
// import { CiShare2 } from "react-icons/ci";
// import { LuBellRing } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import { PlusOutlined } from "@ant-design/icons";
import { Toast } from "primereact/toast";
import { FaBell } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { formatNumber } from "../functions/formatNumber";
import { captureException } from "@sentry/react";
import { FaCalendarDay } from "react-icons/fa";

interface MarketLocationPriceProps {
    marketLocation?: string;
    item?: string;
    state?: string;
    marketName?: string
    marketId?: string;
    onShareClick?: () => void;
}

export default function MarketLocationPrice({ marketLocation, item, state, marketName, marketId, onShareClick }: MarketLocationPriceProps) {
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    const [priceDetails, setPriceDetails] = useState<{ averagePrice: number; unit: string; currency: string; commodityName: string; imageUrl: string; date: string }>()

    useEffect(() => {
        async function determineIfRedirect() {
            const firstPath = window.location.pathname.split('/')[1];
            const firebaseUID = await firebase.auth().currentUser?.uid;
            if (firstPath === 'category') {
                const marketLocationPriceCount = localStorage.getItem('marketLocationPriceCount');
                if (marketLocationPriceCount) {
                    const currentCount = parseInt(marketLocationPriceCount) + 1;
                    if (currentCount === 3 && !firebaseUID) {
                        // navigate('/login')
                    }
                    localStorage.setItem('marketLocationPriceCount', `${currentCount}`);
                } else {
                    const currentCount = 0;
                    localStorage.setItem('marketLocationPriceCount', `${currentCount}`);
                }
            }
        }
        void determineIfRedirect();
    }, [])

    useEffect(() => {
        const addItemToSearch = async (price: number, unit: string) => {
            try {
                await api.post('/search/save', {
                    marketId: marketId,
                    marketName: marketName,
                    unit,
                    item,
                    state,
                    price,
                    userId: firebase.auth().currentUser?.uid
                })
            } catch (error) {
                captureException(error)
            }
        }
        async function getmarketLocations() {
            try {
                setPriceDetails(undefined);
                if (!marketLocation || !item) return;
                const apiResponse = await api.get(`commodity/category-items/price/${item}/${marketLocation}`);
                if (apiResponse.status === 200) {
                    const priceDetailsData = apiResponse.data as { averagePrice: number; unit: string; currency: string; commodityName: string; imageUrl: string; date: string };
                    setPriceDetails(priceDetailsData);
                    (priceDetailsData.averagePrice && priceDetailsData.averagePrice > 0) && await addItemToSearch(priceDetailsData.averagePrice, priceDetailsData.unit);
                }
            } catch (error) {
                captureException(error)
            }
        }
        getmarketLocations()
    }, [item, marketLocation, state, marketName, marketId])

    async function addItemToFoodItem() {
        try {
            const apiResponse = await api.post('/myfooditems/new', {
                marketName: marketName,
                state: state,
                commodityName: item,
                marketId: marketId,
                currentPrice: priceDetails?.averagePrice,
                userId: firebase.auth().currentUser?.uid,
            })
            if ([200, 201].includes(apiResponse.status)) {
                toast.current?.show({ severity: "success", summary: '', detail: `Added ${item} to your list.` });
            }
        } catch (error) {
            captureException(error)
        }
    }

    if (!priceDetails || !priceDetails.averagePrice) return (
        <div className="max-w-sm rounded-lg h-fit overflow-hidden shadow-lg bg-white p-6">
            <p>No current data on this market ({marketName})</p>
        </div>
    )

    return (
        <div className="max-w-sm rounded-lg overflow-hidden shadow-lg bg-white p-6">
            <Toast ref={toast} />
            <img
                className="w-full h-48 object-cover"
                src={priceDetails.imageUrl}
                alt={priceDetails.commodityName}
            />
            <div className="flex justify-between items-center mt-1">
                <div>
                    <h2 className="text-lg font-semibold text-gray-800">{priceDetails?.unit} of {priceDetails?.commodityName}</h2>
                    <p className="text-3xl font-bold text-gray-900 mt-1">{formatNumber(priceDetails?.averagePrice)} {priceDetails?.currency}</p>
                    <div className="flex flex-row gap-1 mt-1">
                        <FaCalendarDay className="mt-1" />
                        <p>{new Date(priceDetails.date).toDateString()}</p>
                    </div>
                </div>
                <button onClick={onShareClick} className="text-gray-500 hover:text-gray-700">
                    <FiShare2 size={20} className="dark:text-white" />
                </button>
            </div>

            <div className="mt-4 space-y-2">
                <button onClick={() => {
                    if (!firebase.auth().currentUser?.uid) {
                        toast.current?.show({ severity: "warn", summary: '', detail: 'You cannot do this without having an account.' });
                        return;
                    }
                    navigate(`/alerts?tab=1&item=${item}&market=${marketName}`)
                }} className="w-full border border-1 border-gray-700 font-semibold py-2 px-4 rounded flex items-center justify-center space-x-2">
                    <FaBell className="text-green-700 dark:text-white" />
                    <p className="dark:text-white">Notify me when price changes</p>
                </button>
            </div>

            <div className="mt-4 flex space-x-2">
                <span className="inline-block bg-gray-100 text-gray-700 text-sm font-bold rounded-md px-3 py-1">{state}</span>
                <span className="inline-block bg-gray-100 text-gray-700 text-xs font-semibold rounded-md px-3 py-1">{marketName}</span>
            </div>
            <div onClick={() => {
                if (!firebase.auth().currentUser?.uid) {
                    toast.current?.show({ severity: "warn", summary: '', detail: 'You cannot do this without having an account.' });
                    return;
                }
                addItemToFoodItem()
            }} className="flex flex-row w-full bg-green-700 rounded-md p-2 mt-3 cursor-pointer">
                <PlusOutlined className="text-xl mt-0 text-white" />
                <p className="text-white text-sm ml-2">Add to my food items</p>
            </div>
        </div>
    )
}