import { useNavigate } from "react-router-dom";
import MyFoodItemsTable from "../features/MyFoodItemsTable";
import "firebase/auth";
import { analytics } from "../firebase-config";

export default function MyFoodItems() {
    analytics.logEvent('page_view', {
        page_title: 'my food items'
    });
    const navigate = useNavigate();
    return (
        <div className="h-[100vh] p-3 bg-[#F7F8FA]">
            <p className="text-[#121737] text-2xl font-bold font-['Work Sans'] leading-normal">My Food Items</p>
            <div className="flex flex-row w-full justify-between mt-5">
                <p className="mt-2">Click to add a food item(s)</p>
                <button onClick={() => navigate('/view-current-prices')} className="w-auto bg-[#E4EFE5] lg:ml-0 ml-5">
                    <p>Search item</p>
                </button>
            </div>
            <hr className="my-5" />
            <MyFoodItemsTable />
        </div>
    )
}