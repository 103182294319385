import Input from "../components/ui/Input";
import { useRef, useState } from "react";
import { Checkbox } from "primereact/checkbox";
// import { FcGoogle } from "react-icons/fc";
// import { FaFacebook } from "react-icons/fa";
import ArketLogo from '../assets/arketLogoWhite.png';
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import { captureException } from "@sentry/react";
import { Password } from "primereact/password";
import { analytics } from "../firebase-config";
import { Helmet } from "react-helmet-async";

export default function Login() {
    analytics.logEvent('page_view', {
        page_title: 'login'
    });
    const navigate = useNavigate();
    const toast = useRef<Toast>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberPassword, setRememberPassword] = useState<boolean | undefined>(false);

    async function doLogin() {
        try {
            if (!email || !password) {
                toast.current?.show({ severity: "error", summary: 'Incomplete form', detail: 'Please fill the form correctly.' });
                return;
            }
            toast.current?.show({ severity: 'info', summary: 'Attempting to login...' });

            await firebase.auth().signInWithEmailAndPassword(email, password);
            const pendingNav = localStorage.getItem('pendingNavigation');
            localStorage.removeItem('pendingNavigation');
            localStorage.removeItem('viewCount');
            if (pendingNav) {
                navigate(pendingNav);
            } else {
                navigate('/home');
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            captureException(error);
            let errorMessage = 'Unable to sign in. Please check your credentials and try again.';
            if (error.code === 'auth/user-not-found') {
                errorMessage = 'No user found with this email address.';
            } else if (error.code === 'auth/wrong-password') {
                errorMessage = 'Incorrect password. Please try again.';
            } else if (error.code === 'auth/invalid-email') {
                errorMessage = 'The email address is not valid.';
            } else if (error.code === 'auth/user-disabled') {
                errorMessage = 'This user account has been disabled.';
            }

            toast.current?.show({ severity: "error", summary: 'Error occurred', detail: errorMessage });
        }
    }

    return (
        <div className="flex flex-col md:flex-row h-screen font-worksans">
            <Helmet>
                <title>Login | Arket data</title>
                <meta name='description' content='Login into your account.' />
                <meta
                    name="keywords"
                    content="Arket login, Arketdata login, Arket data"
                />
            </Helmet>
            <Toast ref={toast} />
            <div className="md:flex-1 flex items-center justify-center bg-[#012D13] hidden md:block">
                <div className="w-full max-w-lg p-8 pt-64 pl-28">
                    <img src={ArketLogo} alt="Side Panel" />
                    <p className="font-semibold text-white text-[36px] mt-5">Discover food item trends 6 months before they take off.</p>
                    <div className="flex flex-col mt-52">
                        <p className="font-semibold text-white">Interested in trying Arket Data?</p>
                        <a href="/signup">
                            <button className="bg-[#027432] w-fit text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline font-bold mt-2" type="button">
                                Sign up here
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex-1 flex items-center justify-center bg-[#F9FFF8]">
                <div className="w-full max-w-lg p-8">
                    <form>
                        <h2 className="text-center text-3xl mb-5 font-semibold font-worksans">Sign in</h2>
                        <p className="text-center text-[#8A92A6] font-worksans mb-12">Login into your account</p>
                        <div className="mb-4">
                            <Input placeholder="your@email.com" type="text" label="Email" onChange={setEmail} name="email" extraStyle="h-[44px] dark:bg-white dark:text-black" />
                        </div>
                        <div className="w-full my-5">
                            <label className="block text-[#8A92A6] text-sm font-bold mb-2" htmlFor={'password'}>Password</label>
                            <Password placeholder="**********" feedback={false} inputId="password" className="lg:w-[445px] w-full" inputClassName="shadow appearance-none border rounded lg:w-[445px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[44px] dark:bg-white dark:text-black" value={password} onChange={(e) => setPassword(e.target.value)} toggleMask />
                        </div>
                        <div className="flex flex-row justify-between mb-10">
                            <div>
                                <Checkbox className="border border-1 rounded-md" inputId="rememberPassword" onChange={e => setRememberPassword(e.checked)} checked={rememberPassword ?? false}></Checkbox>
                                <label htmlFor="rememberPassword" className="ml-2">Remember me?</label>
                            </div>
                            <a href="forgot-password" className="text-[#027432] cursor-pointer">Forgot Password</a>
                        </div>
                        <div className="flex items-center justify-between">
                            <button onClick={doLogin} className="bg-[#027432] w-full text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline font-bold" type="button">
                                Sign In
                            </button>
                        </div>
                        <div className="flex flex-col mt-5">
                            {/* <p className="text-center">Or sign in with other account?</p>
                            <div className="flex flex-row justify-center mt-5 gap-3">
                                <FcGoogle className="text-3xl cursor-pointer" />
                                <FaFacebook className="text-3xl cursor-pointer" />
                            </div> */}
                            <p className="text-center mt-5">Don't have an account? <a href="/signup" className="text-[#3A57E8] cursor-pointer">Click here to sign up</a></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
