import { useNavigate } from "react-router-dom";

interface AlertModalProps {
    isOpen: boolean;
    onClose: () => void;
    itemName: string;
    itemImage: string;
    priceRiseTo?: string;
    priceDropTo?: string;
    price: number;
    email: string;
}

const AddItemModal = ({
    isOpen,
    onClose,
    // itemName,
    // priceRiseTo,
    // priceDropTo,
    email,
    // price
}: AlertModalProps) => {
    const navigate = useNavigate();
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                <h2 className="text-2xl font-bold text-center text-gray-800">Alert Created</h2>
                <p className=" text-gray-600 my-5">
                    You'll be notified when the price of this item drops.
                </p>
                {/* 
                <hr className="my-4" />

                <div className="flex justify-between items-center">
                    <div>
                        <p className="font-semibold text-gray-800">Item</p>
                        <p className="text-green-600 font-semibold">Price Drop Alert</p>
                        <p className="text-lg font-bold text-gray-800">{itemName}</p>
                        <p className="text-green-600">From {priceDropFrom} to {priceDropTo}</p>
                    </div>
                    <img
                        className="w-20 h-20 rounded-md object-cover"
                        src={'https://res.cloudinary.com/makemeup/image/upload/v1723140241/dummyImage_ewgvrh.jpg'}
                        alt={itemName}
                    />
                </div>

                <hr className="my-4" />

                <div>
                    <p className="font-semibold text-gray-800">Price: N{price}</p>
                    <p className="font-bold text-gray-800">Threshold</p>
                    <p className="text-sm text-green-600">When price drops to {priceDropTo}</p>
                    <p className="text-sm text-red-600">When price rises to {priceRiseTo}</p>
                </div>

                <hr className="my-4" /> */}

                <div>
                    <p className="font-semibold text-gray-800">Email</p>
                    <p className="text-gray-900">{email}</p>
                </div>

                <div className="mt-6 flex justify-between">
                    <button
                        onClick={() => navigate('/home')}
                        className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Back to Dashboard
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-green-100 hover:bg-green-200 text-green-600 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Add New Alert
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddItemModal;
